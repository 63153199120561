import { createRoot } from 'react-dom/client';
import { useState } from 'react';
import { App } from './App.jsx';
import VConsole from 'vconsole';
import { getUrlBoolean } from '@/helpers/UrlParam';

import 'uno.css';
import '@/styles/shared.sass';
import '@/styles/core.sass';
import '@/styles/app/index.sass';

const rootContainer = document.getElementById('root');
const root = createRoot(rootContainer);

root.render(<App />);

const debug = getUrlBoolean('debug', false);
if (debug) {
  const vConsole = new VConsole();
}
