// import { motion } from 'framer-motion';
import styles from './Loading.module.sass';
import ReactAnimatedEllipsis from 'react-animated-ellipsis';
import WechatService from '@/services/WechatService';
import { useAppStore } from '@/stores/app';
import classnames from 'classnames';

export const Loading = ({ ...props }) => {
  const loaded = useAppStore((state) => state.loaded);
  const canStart = useAppStore((state) => state.canStart);
  const setCanStart = useAppStore((state) => state.setCanStart);

  return (
    <div
      className={classnames([
        styles.loading,
        loaded && canStart ? styles.hide : null,
      ])}
    >
      <div className={styles.panel}>
        <div className={styles.bg}>
          <div className={styles.pattern} />
        </div>
        {canStart || !loaded ? (
          <div
            className={styles.text}
            font="persona"
          >
            <div className={styles.letter}>
              <div className={styles.box} />
              <span>L</span>
            </div>
            <div className={styles.letter}>
              <span>o</span>
            </div>
            <div className={styles.letter}>
              <span>a</span>
            </div>
            <div className={styles.letter}>
              <div className={styles.box} />
              <span>D</span>
            </div>
            <div className={styles.letter}>
              <span>i</span>
            </div>
            <div className={styles.letter}>
              <span>N</span>
            </div>
            <div className={styles.letter}>
              <span>G</span>
            </div>
            <ReactAnimatedEllipsis />
          </div>
        ) : (
          <button
            className={styles.text}
            font="persona"
            onClick={async () => {
              await WechatService.init();
              // console.log('wechat video autoplay');
              document.querySelector('#video').pause();
              document.querySelector('#video').play();
              setCanStart(true);
            }}
          >
            <div className={styles.letter}>
              <div className={styles.box} />
              <span>S</span>
            </div>
            <div className={styles.letter}>
              <span>T</span>
            </div>
            <div className={styles.letter}>
              <span>a</span>
            </div>
            <div className={styles.letter}>
              <div className={styles.box} />
              <span>R</span>
            </div>
            <div className={styles.letter}>
              <span>T</span>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};
