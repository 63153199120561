import { useEffect } from 'react';

export const useRaf = function (callback) {
  useEffect(() => {
    let raf;

    const onTick = () => {
      callback?.();
      raf = requestAnimationFrame(onTick);
    };

    raf = requestAnimationFrame(onTick);

    return () => {
      if (raf) cancelAnimationFrame(raf);
    };
  }, [callback]);
};

export default useRaf;
