import './App.sass';
import { Suspense, useEffect } from 'react';
import { FileLoader } from 'three';
import { useLoader } from '@react-three/fiber';

import { Webgl } from '@/webgl';
import { Bgm } from '@/components/Bgm';
import { TheMenu } from '@/components/TheMenu/v1/TheMenu';
import { Loading } from '@/components/Loading';
import { TheGuidelines } from '@/components/TheGuidelines';
import { VideoOverlay } from '@/components/VideoOverlay';
import { AnimatePresence } from 'framer-motion';
import { ui } from '@/tunnels';
import { projects } from '@/data/projects';
import WechatService from '@/services/WechatService';
import { Howler } from 'howler';
import { useAppStore } from '@/stores/app';
import { bgm } from '@/sounds';

try {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });
} catch (err) {
  console.log('err unregistering service worker', err);
}

export function App() {
  // *********************************************
  // MUTE AUDIO ON VISIBILITY HIDDEN
  // *********************************************
  const muted = useAppStore((state) => state.muted);
  useEffect(() => {
    const onVisibility = () => {
      if (document['hidden']) {
        Howler.mute(true);
      } else if (!muted) {
        Howler.mute(false);
      }
    };
    document.addEventListener('visibilitychange', onVisibility, false);

    return () => {
      document.removeEventListener('visibilitychange', onVisibility, false);
    };
  }, [muted]);

  // **************************************************
  // AUDIO UNLOCK
  // **************************************************
  const setAudioUnlocked = useAppStore((state) => state.setAudioUnlocked);
  useEffect(() => {
    const onUnlock = () => {
      setAudioUnlocked(true);
    };
    bgm.once('unlock', onUnlock);
    return () => {
      bgm.off('unlock', onUnlock);
    };
  }, []);

  // *********************************************
  // RENDER
  // *********************************************
  return (
    <div
      id="app"
      bg="black"
    >
      <Loading />

      <Webgl />
      <ui.Out />
      <VideoOverlay />
      <TheGuidelines />
      {/* <TheMenu
        // scroll={y}
        projects={projects}
      /> */}
    </div>
  );
}
