import {
  MeshBasicMaterial,
  Texture,
  DoubleSide,
  RepeatWrapping,
  NearestFilter,
  TextureLoader,
} from 'three';

const fontSize = 30;
const padding = 5;

const cache = {};
const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');
const font = `bold ${fontSize * 2}px Persona`;

const textureLoader = new TextureLoader();

ctx.font = font;
ctx.textAlign = 'start';
ctx.textBaseline = 'top';

// document.body.appendChild(canvas);
// canvas.style.position = 'fixed';
// canvas.style.top = '0px';
// canvas.style.left = '0px';
// canvas.style.zIndex = '1000';

window.TextMaterialCache = cache;

export const getMaterial = function (text) {
  if (cache[text]) {
    return cache[text];
  }

  // MEASURE
  const metrics = ctx.measureText(text);
  let actualHeight =
    metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
  actualHeight += fontSize;

  canvas.width = metrics.width + fontSize;
  // canvas.width += 200;
  // canvas.width = 1024;
  // canvas.width = Math.max(canvas.width, 2048);
  canvas.height = actualHeight + padding * 2;
  const aspect = canvas.width / canvas.height;

  // BG
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // TEXT
  ctx.font = font;
  ctx.textAlign = 'start';
  ctx.textBaseline = 'top';

  ctx.strokeStyle = 'black';
  ctx.lineWidth = fontSize * 1;
  ctx.strokeText(text, fontSize * 0.5 + padding, fontSize * 0.5 + padding);

  ctx.fillStyle = 'white';
  ctx.fillText(text, fontSize * 0.5 + padding, fontSize * 0.5 + padding);

  const url = canvas.toDataURL('image/png', 1.0);
  const img = new Image();
  img.src = url;

  // const texture = new Texture(img);
  const texture = textureLoader.load(img);
  texture.wrapS = texture.wrapT = RepeatWrapping;
  texture.magFilter = texture.minFilter = NearestFilter;
  texture.repeat.set((500 / aspect) * -0.5, 1);
  texture.needsUpdate = true;

  const material = new MeshBasicMaterial({
    map: texture,
    side: DoubleSide,
    toneMapped: false,
    transparent: true,
  });

  cache[text] = {
    img,
    texture,
    material,
    aspect,
    width: canvas.width,
    height: canvas.height,
  };
};
