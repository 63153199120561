import { useRef, useMemo, useEffect, useLayoutEffect } from 'react';
import { useThree, createPortal, useFrame } from '@react-three/fiber';
import { Mask, useMask } from '@react-three/drei';
import {
  EffectComposer,
  Outline,
  Selection,
  SMAA,
} from '@react-three/postprocessing';
// import { EffectComposer } from '@/webgl/utils/EffectComposer.tsx';
import { MathUtils, Scene } from 'three';
import { CameraContext } from '@/webgl/utils/CameraContext';
import Env from '@/helpers/Env';
import { BlendFunction } from 'postprocessing';

export const LayerWithEffects = ({ children, enabled = false, ...props }) => {
  const { gl, size } = useThree();

  const stencil = useMask(props.maskId);

  const layerCamera = useRef(null);
  const layerScene = useMemo(() => new Scene(), []);

  const refComposer = useRef();

  useEffect(() => {
    gl.compile(layerScene, layerCamera);
  }, [gl, layerScene, layerCamera]);

  useEffect(() => {
    if (!refComposer.current) return;
    refComposer.current.autoRenderToScreen = false;
  }, [refComposer.current]);

  useLayoutEffect(() => {
    if (!refComposer.current) return;
    refComposer.current.autoRenderToScreen = false;
  }, [refComposer.current]);

  // EVENTS
  const refMesh = useRef();
  // const priority = 1;
  // const compute = (event, state, previous) => {
  //   // First we call the previous state-onion-layers compute, this is what makes it possible to nest portals
  //   if (!previous.raycaster.camera)
  //     previous.events.compute(
  //       event,
  //       previous,
  //       previous.previousRoot.getState()
  //     );
  //   // We run a quick check against the textured plane itself, if it isn't hit there's no need to raycast at all
  //   const [intersection] = previous.raycaster.intersectObject(refMesh.current);
  //   if (!intersection) return false;
  //   // We take that hits uv coords, set up this layers raycaster, et voilà, we have raycasting with perspective shift
  //   const uv = intersection.uv;
  //   state.raycaster.setFromCamera(
  //     state.pointer.set(uv.x * 2 - 1, uv.y * 2 - 1),
  //     layerCamera.current
  //   );
  // };

  return (
    <>
      {props.maskId != undefined && (
        <Mask
          position={[0, size.height * 2 * props.maskProgress, 0]}
          rotation={[0, 0, MathUtils.degToRad(-14)]}
          id={props.maskId}
        >
          <planeGeometry args={[size.width * 2, size.height * 2]} />
        </Mask>
      )}

      <mesh
        ref={refMesh}
        scale={1}
        position={props.position}
        renderOrder={props.renderOrder}
      >
        <planeGeometry args={[size.width, size.height]} />
        <meshBasicMaterial {...(props.maskId != undefined ? stencil : {})}>
          {refComposer.current?.outputBuffer?.texture && (
            <primitive
              object={refComposer.current.outputBuffer.texture}
              attach="map"
            />
          )}
        </meshBasicMaterial>
      </mesh>

      {createPortal(
        <Selection>
          <EffectComposer
            ref={refComposer}
            camera={layerCamera.current}
            scene={layerScene}
            renderPriority={props.renderPriority}
            multisampling={0}
            autoClear={false}
            enabled={enabled}
            stencilBuffer={true}
          >
            <Outline
              visibleEdgeColor="white"
              hiddenEdgeColor="black"
              width={1000}
              blur
              edgeStrength={100}
            />
            <SMAA />
          </EffectComposer>

          <CameraContext.Provider value={layerCamera}>
            {children}
          </CameraContext.Provider>
        </Selection>,
        layerScene
        // {
        //   events: { compute, priority },
        // }
      )}
    </>
  );
};
