import { useRef, useEffect } from 'react';
import { motion } from 'framer-motion-3d';
import { BackSide } from 'three';

export const MaterialOutline = ({ thickness = 1.01, ...props }) => {
  const refMaterial = useRef();

  useEffect(() => {
    let thick = thickness - 1;
    thick = thick.toFixed(5);

    const token = '#include <begin_vertex>';
    const customTransform = `
        #include <begin_vertex>
        vec3 objectNormal = vec3( normal );
        transformed = position + objectNormal * ${thick};
    `;

    refMaterial.current.onBeforeCompile = (shader) => {
      shader.vertexShader = shader.vertexShader.replace(token, customTransform);
    };
    refMaterial.current.needsUpdate = true;
    refMaterial.current.customProgramCacheKey = function () {
      return thick;
    };

    return () => {
      if (!refMaterial.current) return;
      refMaterial.current.onBeforeCompile = (shader) => {
        shader.vertexShader = shader.vertexShader.replace(
          customTransform,
          token
        );
      };
      refMaterial.current.needsUpdate = true;
    };
  }, [thickness]);

  return (
    <motion.meshBasicMaterial
      ref={refMaterial}
      side={BackSide}
      {...props}
    />
  );
};
