
varying vec2 vUv;
uniform vec2 iResolution;
uniform vec3 uColor;
uniform float uTime;
uniform float uSpeedX;
uniform float uSpeedY;
uniform float uRad;
uniform float uRep;
uniform float uSharpness;

const vec2 mid = vec2(0.5);
const float angle = 45.0;
const float RADIANS = angle * 0.0174532;
// rotation matrix
mat2 rot = mat2(vec2(cos(RADIANS), -sin(RADIANS)), vec2(sin(RADIANS), cos(RADIANS)));


void main() {


  ivec2 reps = ivec2(uRep, uRep);  

  // the uv.. we are calling it p for pixel
	vec2 p = vUv.xy;
	// account for non square image aspect
	p.y *= float(iResolution.y )/ float(iResolution.x);
	//rotating the whole scene	
	p *= rot;
	
	
	// q is just an offset - .5
	vec2 q = p - vec2(0.5 + uTime * uSpeedX, 0.5 + uTime * uSpeedY);
	
	
	//=====================
	// wave
	//=====================
	
	// creates a repeating 0-1 range
	vec2 repeat = vec2(fract(q.x * float(reps.x)), fract(q.y * float(reps.y)) );	
	
	// holds the color
	vec3 col = vec3(1.0, 1.0, 1.0);
	
	vec2 distFromMid = repeat - mid;
	
	// drawing circles based on distance from center of each cell
	float dist = length(distFromMid);
	// aliased method
	//float circ = dist < rad ? 1.0 : 0.0;
	// anti-aliased
	float circ = uRad * uSharpness - dist * uSharpness;
	// for black on white, subtract rad from dist
	
	col *= vec3(circ);
  gl_FragColor = vec4(col * uColor, 1.0);

  // float strength = mod((vUv.y + uTime / uSpeed) * uFreq, 1.0) ;
  // strength = step(uGap, strength);
  // gl_FragColor = vec4(uColor.x * strength, uColor.y * strength, uColor.z * strength, 1.0);
}