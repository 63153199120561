import { useEffect, useState, useMemo, useRef } from 'react';
import { MathUtils } from 'three';
import { Arrow } from './Arrow';
import { motion } from 'framer-motion-3d';
import { hover, click } from '@/sounds';
import * as basicMaterials from '@/webgl/materials';
export const ProjectButton = ({
  children,
  project,
  width = 20,
  skew = 0.75,
  color,
  materialProps,
  // hovering = false,
  ...props
}) => {
  // const width = 180;
  // const height = 60;
  // const aspect = width / props.height;

  // const geometry = useMemo(() => {
  //   let geo = new PlaneGeometry(width, props.height);
  //   const matrix = new Matrix4();
  //   matrix.makeShear(0, 0, skew, 0, 0, 0);
  //   geo.applyMatrix4(matrix);
  //   return geo;
  // }, [width, props.height, skew]);

  // const [size, setSize] = useState({ width: 0, height: 0 });
  const refRoot = useRef();
  // const { size } = useBounds(refRoot);

  const [hovering, setHovering] = useState(false);

  // useEffect(() => {
  //   if (hovering) {
  //     document.body.classList.add('hand');
  //   } else {
  //     document.body.classList.remove('hand');
  //   }
  //   if (hovering) hover.play();
  // }, [hovering]);

  return (
    <group
      {...props}
      ref={refRoot}
      onPointerOver={(evt) => {
        // evt.stopPropagation();
        setHovering(true);
      }}
      onPointerOut={(evt) => {
        // evt.stopPropagation();
        setHovering(false);
      }}
      onClick={(evt) => {
        evt.stopPropagation();
        click.play();
        props.onClick?.();
      }}
    >
      <motion.group
        initial={{ x: 100 }}
        animate={{ x: hovering ? 10 : 0 }}
        exit={{ x: 100 }}
      >
        <Arrow
          scale={14}
          shapeScale={[2, width, 1]}
          position={[-width * 10, 0, 0]}
          rotation={[0, 0, MathUtils.degToRad(-2)]}
          color={color}
          active={true}
          materialProps={materialProps}
        />

        <Arrow
          scale={14}
          shapeScale={[2, width, 1]}
          position={[-width * 10 - 10, 0, 0]}
          rotation={[0, 0, MathUtils.degToRad(0)]}
          color={hovering ? '#FFFFFF' : '#000000'}
          active={true}
          materialProps={materialProps}
        />

        {children}

        {/* <mesh position={[0, 0, 3]}>
          <circleGeometry args={[2]} />
          <primitive attach="material" object={basicMaterials.black} />
        </mesh> */}
      </motion.group>
    </group>
  );
};
