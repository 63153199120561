import { useRef, useEffect, useMemo } from 'react';
import { useFrame } from '@react-three/fiber';
import { clamp } from '@/helpers/MathUtils';
import { AnimationMixer } from 'three';

export const useAnimationProgress = (ref, clip, progress = 0) => {
  let mixer = useRef(null);
  let action = useRef(null);
  let duration = useRef(null);

  useEffect(() => {
    mixer.current = new AnimationMixer(ref.current);
    action.current = mixer.current.clipAction(clip);
    action.current.play();
    duration.current = clip.duration * 0.999999999;
  }, [ref, clip]);

  useFrame((state, delta) => {
    if (!mixer.current) return;
    mixer.current.time = 0;
    action.current.time = 0;
    let p = clamp(progress, 0, 1);
    mixer.current.update(p * duration.current);
  });

  return {
    mixer,
    action,
  };
};
