import { useThree } from '@react-three/fiber';
import { RenderTexture } from '@react-three/drei';

export const Layer = ({ children, ...props }) => {
  const { size } = useThree();

  return (
    <>
      <mesh>
        <planeGeometry args={[size.width, size.height]} />
        <meshBasicMaterial transparent={true}>
          <RenderTexture
            attach="map"
            renderPriority={props.renderPriority}
            stencilBuffer={true}
          >
            {children}
          </RenderTexture>
        </meshBasicMaterial>
      </mesh>
    </>
  );
};
