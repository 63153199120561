const projects = [
  {
    id: 'intro',
    color: '#f40000',
    email: 'ethan@ethanchiu.dev',
    title: 'Ethan Chiu',
    linkedin: 'https://www.linkedin.com/in/ethanchiu10/',
    lockCase: false,
  },

  // ******************************************************************************************
  {
    id: 'keepers',
    link: 'https://kprverse.com/',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz/#!/work/kprverse/',
    },
    tags: [
      {
        label: 'FWA of the Month',
        link: 'https://thefwa.com/cases/kpr',
      },
      {
        label: 'Awwwards Site of the Year',
        link: 'https://www.awwwards.com/annual-awards-2022/site-of-the-year',
      },
      {
        label: 'Nuxt.js',
      },
    ],
    year: '2022',
    color: '#968adf',
    title: 'KPRVerse',
    hidden: false,
    category: {
      label: 'website',
    },
    lockCase: false,
    youtubeId: 'Q4QU7WsJDgg',
  },

  // ******************************************************************************************
  {
    id: 'airbnb-moreofless',
    link: 'https://moreofless.ethanchiu.dev/',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz/#!/work/airbnb-more-of-less/',
    },
    tags: [
      {
        label: 'FWA of the Day',
        link: 'https://thefwa.com/cases/more-of-less',
      },
      {
        label: 'Vue.js',
      },
      {
        label: 'A-Frame',
      },
    ],
    year: '2018',
    color: '#f7585c',
    title: 'AirBnB More of Less',
    youtubeId: 'xh02qJ9RavA',
  },

  // ******************************************************************************************
  {
    id: 'cocacola-nyg22',
    link: 'https://cocacola-nyg22.ethanchiu.dev/?skipBrowserCheck',
    client: {
      label: 'Unit9',
      link: 'https://www.unit9.com/project/coca-cola-chinese-new-year-games/',
    },
    tags: [
      {
        label: 'FWA of the Day',
        link: 'https://thefwa.com/cases/coca-cola-chinese-new-year-games',
      },
      {
        label: 'AR',
      },
      {
        label: 'Blink Controls',
      },
    ],
    year: '2022',
    color: '#fe001a',
    title: 'Coca-Cola New Year Games',
    youtubeId: 'skWOpxsMHoM',
  },

  // ******************************************************************************************
  {
    id: 'kfc-chickendales',
    link: 'https://chickendales.ethanchiu.dev/',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz/#!/work/kfc-chickendales/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'CSS',
      },
    ],
    year: '',
    color: '#f26778',
    title: 'KFC Chickendales',
    hidden: false,
    category: {
      label: 'experience',
    },
    lockCase: false,
    youtubeId: 'LhsWI6KNqJo',
  },

  // ******************************************************************************************
  {
    id: 'goodmeat-2020',
    link: 'https://www.goodmeat.co/',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'Three.js',
      },
    ],
    year: '2020',
    color: '#8b2c05',
    title: 'GOOD Meat 2020',
    hidden: false,
    category: {
      label: 'website',
    },
    lockCase: false,
    youtubeId: '4H7iA5EImrE',
  },

  // ******************************************************************************************
  {
    id: 'tencent-moonments',
    link: 'https://moonments.ethanchiu.dev/',
    client: {
      label: 'Stink Studios',
      link: 'https://stinkstudios.com/work/tencent-moonments',
    },
    tags: [
      {
        label: 'FWA of the Day',
        link: 'https://thefwa.com/cases/tencent-moonments-p2',
      },
      {
        label: 'Spikes Shortlist',
        link: 'https://www2.spikes.asia/winners/2021/brand_experience/entry.cfm?entryid=380&award=99&order=2&direction=1',
      },
      {
        label: 'A-Frame',
      },
    ],
    year: '2019',
    color: '#cbccd6',
    title: 'Tencent Moonments',
    youtubeId: 'xouI0w8ABOQ',
  },

  // ******************************************************************************************
  {
    id: 'netflix-yourhamface',
    link: 'https://yourhamface.ethanchiu.dev/',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'PixiJS',
      },
    ],
    year: '',
    color: '#ec833e',
    title: 'Netflix Your Ham Face',
    hidden: false,
    category: {
      label: 'experience',
    },
    lockCase: false,
    youtubeId: '3bdRHxyavbQ',
  },

  // ******************************************************************************************
  {
    id: 'lvmh-prize',
    link: 'https://www.lvmhprize.com/',
    client: {
      label: 'B-REEL',
      link: 'https://www.b-reel.com/',
    },
    tags: [
      {
        label: 'FWA of the Day',
        link: 'https://thefwa.com/cases/lvmh-prize',
      },
      {
        label: 'Three.js',
      },
    ],
    year: '2023',
    color: '#B4F105',
    title: 'LVMH Prize',
    hidden: true,
    category: {
      label: 'website',
    },
    youtubeId: 'eWLsa3lfnBw',
  },

  // ******************************************************************************************
  {
    id: 'jordan-spacejam',
    link: 'https://spacejam.ethanchiu.dev/',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'Three.js',
      },
    ],
    year: '2017',
    color: '#5050a5',
    title: 'Jordan Space Jam',
    youtubeId: 'aN6WuzLxnQg',
  },

  // ******************************************************************************************
  {
    id: 'maisonalt',
    link: 'https://www.maisonalt.co/',
    client: {
      label: 'Stink Studios',
      link: 'https://www.stinkstudios.com/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'Three.js',
      },
    ],
    color: '#e8e8e8',
    title: 'MAISONALT',
    youtubeId: 'tfr5z53y6y4',
  },

  // ******************************************************************************************
  {
    id: 'tencent-palebluedot',
    link: 'https://palebluedot.ethanchiu.dev/',
    client: {
      label: 'Stink Studios',
      link: 'https://www.stinkstudios.com/',
    },
    tags: [
      {
        label: 'FWA of the Day',
        link: 'https://thefwa.com/cases/pale-blue-dot',
      },
      {
        label: 'Spikes Shortlist',
        link: 'https://www2.spikes.asia/winners/2022/mobile/entry.cfm?entryid=721&award=101',
      },
      {
        label: 'A-Frame',
      },
    ],
    year: '2020',
    color: '#abb2c7',
    title: 'Tencent Pale Blue Dot',
    youtubeId: '9zWbuZTV9aQ',
  },

  // ******************************************************************************************
  {
    id: 'chanel-cococrush',
    link: 'https://cococrush.ethanchiu.dev/',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'PixiJS',
      },
    ],
    year: '2018',
    color: '#FFFFFF',
    title: 'Chanel Coco Crush',
    youtubeId: 'PmIVFwG7Vl8',
  },

  // ******************************************************************************************
  {
    id: 'discover-lexus',
    link: 'https://discoverlexus.com/',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz',
    },
    tags: [
      {
        label: 'Nuxt.js',
      },
      {
        label: 'Statamic',
      },
      // {
      //   label: 'FWA of the Day',
      //   link: 'https://thefwa.com/cases/discover-lexus',
      // },
    ],
    year: '',
    color: '#8c8c8c',
    title: 'Discover Lexus',
    hidden: false,
    category: {
      label: 'website',
    },
    lockCase: false,
    youtubeId: 'SMztGHP1VqU',
  },

  // ******************************************************************************************
  {
    id: 'tencent-microuniverse',
    link: 'https://we.ethanchiu.dev/',
    client: {
      label: 'Stink Studios',
      link: 'https://www.stinkstudios.com/',
    },
    tags: [
      {
        label: 'FWA of the Day',
        link: 'https://thefwa.com/cases/tencent-we-micro-universe',
      },
      // {
      //   label: "ADC Awards Merit Honor",
      //   link: 'https://www.oneclub.org/awards/adcawards/-award/40594/micro-universe'
      // }
    ],
    year: '2019',
    color: '#d4b08a',
    title: 'Tencent Microuniverse',
    youtubeId: 'JqzvkSF1aI4',
  },

  // ******************************************************************************************
  {
    id: 'nike-feedofspeed',
    link: 'https://feedofspeed.ethanchiu.dev/',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com',
    },
    tags: [
      {
        label: 'FWA of the Day',
        link: 'https://thefwa.com/cases/nike-feed-of-speed',
      },
      {
        label: 'Three.js',
      },
    ],
    year: '2017',
    color: '#1652e2',
    title: 'Nike Feed of Speed',
    youtubeId: 'zfsDhdD-b5s',
  },

  // ******************************************************************************************
  {
    id: 'apeel',
    link: 'https://apeel.com/',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz',
    },
    tags: [
      {
        label: 'Nuxt.js',
      },
      {
        label: 'Storyblok',
      },
    ],
    color: '#d2dd97',
    title: 'Apeel',
    youtubeId: 'GAFYqARmJWo',
  },

  // ******************************************************************************************
  {
    id: 'nike-filterid',
    link: 'https://nikefilterid.ethanchiu.dev/',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'Nike',
      },
    ],
    color: '#feb522',
    title: 'Nike Filter ID',
    youtubeId: 'HBsfsMsqmRg',
  },

  // ******************************************************************************************
  {
    id: 'goodmeat-2021',
    link: 'https://www.goodmeat.co/',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz',
    },
    tags: [
      {
        label: 'Nuxt.js',
      },
      {
        label: 'Storyblok',
      },
    ],
    year: '',
    color: '#dabd71',
    title: 'GOOD Meat 2021',
    hidden: false,
    category: {
      label: 'website',
    },
    lockCase: false,
    youtubeId: 'Gof58_2Yhx4',
  },

  // ******************************************************************************************
  {
    id: 'valmont',
    link: 'https://valmont.com/',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz',
    },
    tags: [
      {
        label: 'Nuxt.js',
      },
      {
        label: 'Sitefinity',
      },
    ],
    year: '2023',
    color: '#015f83',
    title: 'Valmont',
    hidden: true,
    category: {
      label: 'website',
    },
    youtubeId: 'pH3u66kPsSE',
  },

  // ******************************************************************************************
  {
    id: 'jordan-courtzero',
    link: 'https://courtzero.ethanchiu.dev/',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com',
    },
    tags: [
      {
        label: 'AngularJS',
      },
      {
        label: 'CSS',
      },
    ],
    year: '2016',
    color: '#c31918',
    title: 'Jordan Court Zero',
    youtubeId: 'j-nZWRXkneM',
  },

  // ******************************************************************************************
  {
    id: 'adidas-icons',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'Three.js',
      },
    ],
    color: '#6aa4b7',
    title: 'Adidas Icons',
    youtubeId: '0qpm736uFq0',
  },

  // ******************************************************************************************
  {
    id: 'nike-remixmyairs',
    link: 'https://remixmyairs.ethanchiu.dev/',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'PixiJS',
      },
    ],
    year: '2017',
    color: '#f0dc91',
    title: 'Nike Remix My Airs',
    youtubeId: 'NhBXn-9IpzY',
  },

  // ******************************************************************************************
  {
    id: 'nba-qmq',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'Three.js',
      },
    ],
    year: '',
    color: '#2578d1',
    title: 'NBA QMQ',
    hidden: false,
    category: {
      label: 'experience',
    },
    lockCase: false,
    youtubeId: 'StGXnCoygRI',
  },

  // ******************************************************************************************
  {
    id: 'nike-gtm',
    client: {
      label: 'Unit9',
      link: 'https://www.unit9.com/project/nike-app-gtm',
    },
    tags: [
      {
        label: 'FWA of the Day',
        link: 'https://thefwa.com/cases/nike-app-gtm',
      },
      {
        label: 'Three.js',
      },
    ],
    year: '2022',
    color: '#dffec3',
    title: 'Nike App GTM',
    youtubeId: 'sKtP0brkNmg',
  },

  // ******************************************************************************************
  {
    id: 'ricola-herbhunt',
    link: 'https://www.ricolaxpsj.com/?game',
    client: {
      label: 'Uncanny',
      link: 'https://www.thisisuncanny.com/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'Three.js',
      },
    ],
    year: '2023',
    color: '#ffc600',
    title: 'Ricola Herb Hunt',
    hidden: false,
    category: {
      label: 'game',
    },
    youtubeId: '8XzheGTdvf4',
  },

  // ******************************************************************************************
  {
    id: 'oishi-guguniao',
    link: 'https://guguniao.ethanchiu.dev/',
    client: {
      label: 'TheRightKind',
      link: 'https://www.itstrk.com/home',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'PixiJS',
      },
    ],
    year: '2019',
    color: '#fed300',
    title: 'Oishi Guguniao',
    youtubeId: 'xHRd9kE1AwY',
  },

  // ******************************************************************************************
  {
    id: 'nba-tipoff2018',
    link: 'https://nbatipoff.ethanchiu.dev/',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com',
    },
    tags: [
      {
        label: 'Vue.js',
      },
    ],
    year: '2018',
    color: '#01519f',
    title: 'NBA Tip Off',
    youtubeId: 'SAtHOcP652I',
  },

  // ******************************************************************************************
  {
    id: 'martell-tasteoflife',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'Three.js',
      },
    ],
    year: '2018',
    color: '#001545',
    title: 'Martell Taste of Life',
    youtubeId: 'RwTKMEZQ2_g',
  },

  // ******************************************************************************************
  {
    id: 'burberry-cvd',
    link: 'https://burberrycvd.ethanchiu.dev/',
    client: {
      label: 'Burberry',
      link: 'https://www.burberry.com/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
    ],
    year: '2019',
    color: '#bc5532',
    title: 'Burberry CVD',
    youtubeId: 'e1v4ZlpUgNk',
  },

  // ******************************************************************************************
  {
    id: 'bohanphoenix-overseas',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'Three.js',
      },
    ],
    year: '2018',
    color: '#35315b',
    title: 'Bohan Phoenix: Overseas',
    youtubeId: 'rdyoNY20wME',
  },

  // ******************************************************************************************
  {
    id: 'nike-mysolestory',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com',
    },
    tags: [
      {
        label: 'AngularJS',
      },
    ],
    year: '2017',
    color: '#cdff00',
    title: 'Nike My Sole Story',
    youtubeId: 'RWO-RHNZ40s',
  },

  // ******************************************************************************************
  {
    id: 'nike-freerevolution',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com',
    },
    tags: [
      {
        label: 'AngularJS',
      },
    ],
    year: '2017',
    color: '#62c4f7',
    title: 'Nike Free Revolution',
    youtubeId: 'IQNkILQzCWY',
  },

  // ******************************************************************************************
  {
    id: 'nike-amd2016',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com',
    },
    tags: [
      {
        label: 'AngularJS',
      },
    ],
    year: '2016',
    color: '#94c5df',
    title: 'Nike Air Max Day 2016',
    lockCase: false,
    youtubeId: 'YZaln55LR5w',
  },

  // ******************************************************************************************
  {
    link: 'https://climacool.ethanchiu.dev/',
    client: {
      label: 'MediaMonks',
      link: 'https://media.monks.com/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
    ],
    year: '2019',
    color: '#7f54a8',
    title: 'Adidas ClimaCool',
    category: {
      label: 'game',
    },
    youtubeId: '',
    hidden: true,
  },

  // ******************************************************************************************
  {
    id: 'wisr',
    link: 'https://wisr.com.au/',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz',
    },
    tags: [
      {
        label: 'Gatsby',
      },
      {
        label: 'Statamic',
      },
    ],
    year: '',
    color: '#88c6bd',
    title: 'WISR',
    hidden: false,
    category: {
      label: 'website',
    },
    lockCase: false,
    youtubeId: 'ZoQdVqEXtLY',
  },

  // ******************************************************************************************
  {
    id: 'lv-watchprize',
    link: 'https://www.louisvuittonwatchprize.com/',
    client: {
      label: 'B-REEL',
      link: 'https://www.b-reel.com/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
    ],
    year: '2023',
    color: '#453630',
    title: 'LV Watch Prize',
    hidden: false,
    category: {
      label: 'website',
    },
    youtubeId: 'Qa6MpDdvKaw',
  },

  // ******************************************************************************************
  {
    id: 'mitte',
    link: 'https://mitte.ca/',
    client: {
      label: 'Mitte',
      link: 'https://mitte.ca/',
    },
    tags: [
      {
        label: 'Next.js',
      },
      {
        label: 'Stripe',
      },
      {
        label: 'Vue.js',
      },
    ],
    color: '#bae7e8',
    title: 'mitte: Pharmacy to you',
    youtubeId: '45DEHQsOODw',
  },

  // ******************************************************************************************
  {
    id: 'netflix-bookclub',
    link: 'https://www.netflixbookclub.com/',
    client: {
      label: 'Netflix',
      link: 'https://netflix.com',
    },
    tags: [],
    color: '#feaa76',
    title: 'Netflix Book Club',
    youtubeId: '9ho6xvp16bQ',
  },

  // ******************************************************************************************
  {
    id: 'weima-brandguide',
    link: 'https://brand.wm-motor.com/',
    client: {
      label: 'BBH',
      link: 'https://www.bartleboglehegarty.com/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
    ],
    color: '#30c094',
    title: 'Weltmeister Brand Guide',
    youtubeId: 'w71x4ApMLbM',
  },

  // ******************************************************************************************
  {
    id: 'greystar-livn',
    link: 'https://livn.greystar.cn/',
    client: {
      label: 'BBH',
      link: 'https://www.bartleboglehegarty.com/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
    ],
    color: '#cc6950',
    title: "Greystar LIV'N",
    youtubeId: 'TZcF-zMsSRQ',
  },

  // ******************************************************************************************
  {
    id: 'netflix-stranded',
    link: 'https://stranded.ethanchiu.dev/',
    client: {
      label: 'Netflix',
      link: 'https://netflix.com',
    },
    tags: [],
    color: '#E50914',
    title: 'Netflix The Stranded',
    youtubeId: 'J53H0cWEjww',
  },

  // ******************************************************************************************
  {
    id: 'antica-shpopup',
    link: 'https://antica-gelateria.ethanchiu.dev/',
    client: {
      label: 'IT Consultis',
      link: 'https://it-consultis.com/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
    ],
    color: '#fccf3f',
    title: 'Antica Gelateria del Corso Popup',
    youtubeId: 'xVimwQCqfYc',
  },

  // ******************************************************************************************
  {
    id: '361-interactiveshoe',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'Three.js',
      },
    ],
    color: '#5efdf1',
    title: '361 Interactie Shoe',
    youtubeId: 'Zvlws5Wvkk0',
  },

  // ******************************************************************************************
  {
    id: 'galaxymacau-idd',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com/',
    },
    tags: [
      {
        label: 'AngularJS',
      },
      {
        label: 'Three.js',
      },
    ],
    color: '#f3be4b',
    title: 'Galaxy Macau Directory',
    youtubeId: 'NXv9HYxNp2w',
  },

  // ******************************************************************************************
  {
    id: 'lincoln-studio',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com/',
    },
    tags: [
      {
        label: 'AngularJS',
      },
      {
        label: 'Autodesk VRED',
      },
    ],
    color: '#c66f2f',
    title: 'Lincoln Personalization Studio',
    youtubeId: 'HZcgJ8mttYQ',
  },

  // ******************************************************************************************
  {
    id: 'ant-forest',
    client: {
      label: 'Resn',
      link: 'https://resn.co.nz/',
    },
    tags: [
      {
        label: 'Vue.js',
      },
      {
        label: 'Three.js',
      },
    ],
    color: '#637f4e',
    title: 'AntFinancial Forest',
    youtubeId: 'GJZQTKFnwDc',
  },

  // ******************************************************************************************
  {
    id: 'ge-trendstable',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com/',
    },
    tags: [
      {
        label: 'AngularJS',
      },
      {
        label: 'Three.js',
      },
    ],
    color: '#4171a3',
    title: 'GE Trends Table',
    youtubeId: '04X-3Kn4chM',
  },

  // ******************************************************************************************
  {
    id: 'ford-applinkinspire',
    client: {
      label: 'AKQA',
      link: 'https://akqa.com/',
    },
    tags: [
      {
        label: 'AngularJS',
      },
      {
        label: 'PixiJS',
      },
    ],
    color: '#8bd0e2',
    title: 'Ford AppLink Inspire',
    youtubeId: 'W1FsLDJvGk8',
  },

  // ******************************************************************************************
];

export default projects;
