import { useGLTF, PerspectiveCamera } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Select } from '@react-three/postprocessing';
import { useRef, useEffect, useContext, useState } from 'react';

import { Ground } from '@/webgl/objects/Ground';
import { Sky } from '@/webgl/objects/Sky';
import { TextRings } from '@/webgl/objects/TextRings';
import { MaterialOutline } from '@/webgl/materials/MaterialOutline';
import { MaterialProject } from '@/webgl/materials/MaterialProject';
import {
  MathUtils,
  BackSide,
  FrontSide,
  DoubleSide,
  NearestFilter,
} from 'three';
import { CameraContext } from '@/webgl/utils/CameraContext';
import { HoverControls } from '@/webgl/controls/HoverControls';
import { HoldZoomControls } from '@/webgl/controls/HoldZoomControls';
import { MobileDistance } from '@/webgl/utils/MobileDistance';
import { useAnimationProgress } from '@/webgl/hooks/useAnimationProgress';
import { useAnimations } from '@/webgl/hooks/useAnimations';
import { MaterialLines } from '@/webgl/materials/MaterialLines';
import Env from '@/helpers/Env';
import * as basicMaterials from '@/webgl/materials';
import { useAppStore } from '@/stores/app';
import { WatchButton } from '@/webgl/utils/WatchButton';

// const url = 'assets/models/shibuya/shibuya.glb';
const url = 'assets/models-compressed/shibuya/shibuya-o.glb';

export const Shibuya = ({ project, progress, enabled, ...props }) => {
  const { size } = useThree();

  const color = project.color;

  // CAMERA
  const refCamera = useContext(CameraContext);

  // GLTF
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(url);

  // ANIMATION
  useAnimationProgress(group, animations[0], progress);

  // useAnimations(group, animations[0], 2);

  const [pattern, setPattern] = useState('lines');
  useEffect(() => {
    setPattern(Math.random() > 0.35 ? 'lines' : 'dots');
  }, [project]);

  return (
    <group>
      <directionalLight
        intensity={(Env.mobile ? 0.8 : 1) * 0.02}
        position={[-1, 1, 3]}
      />

      <Select enabled>
        <Ground
          size={300}
          speed={-0.007}
          freq={120}
          gap={0.5}
          color={color}
          sharpness={10}
          pattern={pattern}
          showOutline={false}
          showSky={true}
        />
      </Select>

      {/* <TextRings
        position={[0, 0, 0]}
        project={project}
        repeat={Math.ceil(11 / (project.tags?.length || 1))}
        radius={300}
        height={3}
        speed={0.0002}
        speedMultiplier={0.25}
        enabled={enabled}
      /> */}
      {/* <mesh
        position={[0, 0, 0]}
        rotation={[MathUtils.degToRad(180), 0, 0]}
      >
        <sphereGeometry args={[100, 128, 16]} />

        <MaterialLines
          color={color}
          side={DoubleSide}
          speed={-0.0125}
          freq={140}
          gap={0.5}
        />
      </mesh> */}
      <group
        ref={group}
        {...props}
        dispose={null}
      >
        <group name="Scene">
          <group
            name="CameraAnimation"
            position={[-20.245, 11.174, -17.38]}
            rotation={[0.291, 0.759, -0.203]}
          >
            <HoverControls
              easeX={0.1}
              easeY={0.1}
              easeZ={0.1}
              posX={2}
              posY={Env.mobile ? 0 : -0.8}
              posZ={0.5}
              rotX={Env.mobile ? 0 : 0.02}
              rotY={0.04}
              rotZ={-0.0}
            >
              <HoldZoomControls
                from={23}
                to={23 - 6}
              >
                <MobileDistance distance={(size.height / size.width) * 0}>
                  <PerspectiveCamera
                    name="Camera"
                    ref={refCamera}
                    makeDefault={true}
                    far={2000}
                    near={0.1}
                    fov={Env.mobile ? 31 : 23}
                    position={[0, 1, 0.252]}
                    rotation={[MathUtils.degToRad(-3), 0, 0]}
                  />
                </MobileDistance>
              </HoldZoomControls>
            </HoverControls>
          </group>

          <Select enabled>
            <mesh
              name="Buildings"
              geometry={nodes.Buildings.geometry}
              position={[-18.425, 8.37, -33.399]}
              scale={3.345}
            >
              <primitive
                attach="material"
                object={basicMaterials.toonBlack}
              />
            </mesh>

            <WatchButton
              project={project}
              enabled={enabled}
            >
              <mesh
                name="Screen"
                geometry={nodes.Screens.geometry}
                position={[-15.032, 11.565, -39.394]}
                rotation={[0, 0, -Math.PI / 2]}
                scale={3.345}
              >
                <MaterialProject
                  enabled={enabled}
                  project={project}
                  // depthTest={false}
                />
              </mesh>
            </WatchButton>
          </Select>

          <group rotation={[Math.PI / 2, 0, 2.302]}>
            <mesh geometry={nodes.Man.geometry}>
              <MaterialOutline
                color={'white'}
                thickness={1.035}
                transparent={true}
                depthTest={false}
              />
            </mesh>
            <mesh
              name="Man"
              geometry={nodes.Man.geometry}
            >
              {/* <meshBasicMaterial
                color={'black'}
                transparent={true}
                depthTest={false}
              /> */}
              <primitive
                attach="material"
                object={basicMaterials.blackTransparentNoDepth}
              />
            </mesh>
          </group>
        </group>
      </group>
    </group>
  );
};

useGLTF.preload(url);
