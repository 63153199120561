import { useState, useEffect } from 'react';
import { OutlineText } from './OutlineText';
import { MathUtils } from 'three';
import { Arrow } from './Arrow';
import { ProjectButton } from './ProjectButton';
import { HoverArrows } from './HoverArrows';
import { Plane, Circle, useTexture, useMask } from '@react-three/drei';
import { motion } from 'framer-motion-3d';
import { A11y } from '@react-three/a11y';
import Env from '@/helpers/Env';
import * as basicMaterials from '@/webgl/materials';
import { menu } from '@/sounds';
import { useAppStore } from '@/stores/app';

export const ProjectMenu = ({ show, project, ...props }) => {
  const color = project?.color || 'white';
  const setWatch = useAppStore((state) => state.setWatch);

  // ICONS
  const iconEmail = useTexture('assets/images/icons/email.png');
  const iconLinkedin = useTexture('assets/images/icons/linkedin.png');
  const iconLink = useTexture('assets/images/icons/link.png');
  const iconYoutube = useTexture('assets/images/icons/youtube.png');

  // BUTTONS
  const youtubeUrl = project?.youtubeId
    ? `https://www.youtube.com/watch?v=${project.youtubeId}`
    : null;
  const emailUrl = project?.email ? `mailto:${project.email}` : null;

  const button1 = {
    label: project?.email ? 'EMAIL' : 'LINK',
    href: emailUrl || project?.link || null,
  };

  const button2 = {
    label: project?.linkedin ? 'LINKEDIN' : 'WATCH',
    href: project?.linkedin || youtubeUrl || null,
  };

  // HOVER
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    menu.play();
  }, []);

  return (
    <group
      name="Project Menu"
      dispose={null}
      // position={[Env.mobile ? 20 : 30, Env.mobile ? 22 : 36, 0]}
      // rotation={[0, 0, MathUtils.degToRad(28)]}
      // scale={Env.mobile ? 0.7 : 1}
    >
      {/* <Circle
        args={[280, 10, 0, Math.PI * 0.5]}
        position={[-20, 0, 0]}
        rotation={[0, 0, MathUtils.degToRad(-28 - 30)]}
        onPointerOut={props.onPointerOut}
      >
        <meshBasicMaterial
          color={'black'}
          transparent={true}
          opacity={0.5}
        />
      </Circle> */}

      <HoverArrows color={color} />

      <motion.group
        name="ButtonYouTube"
        // position={[105, 22, 0]}
        position={[110, -6, 0]}
        rotation={[0, 0, MathUtils.degToRad(-5)]}
        // onPointerOver={() => setHovering('youtube')}
        // onPointerOut={() => setHovering(null)}
        visible={button2.href != null}
      >
        <A11y
          role="link"
          href={button1.href}
        >
          <ProjectButton
            width={button2.label == 'LINKEDIN' ? 15 : 13.5}
            color={color}
            onClick={(evt) => {
              console.log('onClick', button2.label, project.id);
              if (button2.label == 'LINKEDIN') {
                window.open(button2.href, '_blank');
                return;
              } else {
                setWatch(project);
              }
            }}
            // hovering={hovering == 'youtube'}
          >
            <group position={[38, -20, 1]}>
              <OutlineText
                fontSize={20}
                text={button2.label}
                hasOutline={false}
                lockCase={true}
                margin={-1}
                rotationAmount={0}
              />
            </group>

            <Plane
              args={[26, 26]}
              position={[25, 0, 1]}
              scale={button2.label == 'LINKEDIN' ? 0.8 : 1}
            >
              <motion.meshBasicMaterial
                map={button2.label == 'LINKEDIN' ? iconLinkedin : iconYoutube}
                color={color}
                transparent={true}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                // {...stencil}
              />
            </Plane>
          </ProjectButton>
        </A11y>
      </motion.group>

      <group
        name="ButtonLink"
        // position={[110, -6, 0]}
        position={[105, 22, 0]}
        rotation={[0, 0, MathUtils.degToRad(15)]}
        // onPointerOver={() => setHovering('link')}
        // onPointerOut={() => setHovering(null)}
        visible={button1.href != null}
      >
        <A11y
          role="link"
          href={button1.href}
        >
          <ProjectButton
            width={button1.label == 'EMAIL' ? 12 : 10}
            color={color}
            onClick={(evt) => {
              // evt.stopPropagation();
              console.log('onClick', 'LINK', project.id);
              window.open(button1.href, '_blank');
            }}
            // hovering={hovering == 'link'}
          >
            <group position={[38, -20, 0]}>
              <OutlineText
                fontSize={20}
                text={button1.label}
                hasOutline={false}
                lockCase={true}
                margin={-1}
                rotationAmount={0}
              />
            </group>

            <Plane
              args={[26, 26]}
              position={[25, 0, 1]}
              scale={button1.label == 'EMAIL' ? 0.8 : 1}
            >
              <motion.meshBasicMaterial
                map={button1.label == 'EMAIL' ? iconEmail : iconLink}
                color={color}
                transparent={true}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                // {...stencil}
              />
            </Plane>
          </ProjectButton>
        </A11y>
      </group>

      {/* <mesh position={[0, 0, 5]}>
        <circleGeometry args={[2]} />
        <primitive attach="material" object={basicMaterials.black} />
      </mesh> */}
    </group>
  );
};
