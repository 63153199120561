// import postscribe from 'postscribe'
// import 'whatwg-fetch'
// import 'url-search-params-polyfill';
// import '@/libs/jweixin-1.6.0.js'
import promiseInjectScript from '@/helpers/InjectScript';
import { getUrlBoolean } from '@/helpers/UrlParam';
import isClient from '@/helpers/isClient';

// WECHAT SERVICE
class WechatService {
  constructor() {
    this.lastUrl = null;
    this.injected = false;
    this.isWechat = isClient
      ? /micromessenger/i.test(navigator.userAgent)
      : false;
    this.isMiniProgram = false;
    this.debugEnabled = getUrlBoolean('_wechatDebug');
  }

  init = async () => {
    console.log('WechatService.init');

    if (!this.isWechat) {
      console.log('WechatService.init: not inside Wechat');
      return;
    }

    console.log('WechatService.init: inside Wechat');
    const success = await this.injectScript();
    if (!success) {
      await this.injectScript('//res2.wx.qq.com/open/js/jweixin-1.6.0.js');
    }
    console.log('WechatService.init', success, this.injected);
    this.isMiniProgram = window.__wxjs_environment === 'miniprogram';
    // store.commit('browser/setIsMiniProgram', this.isMiniProgram)
  };

  initShare = async () => {
    await this.updateConfig();
    await this.enableShare();
    await this.setDefaultLink();
  };

  injectScript(overrideSrc) {
    const src = overrideSrc || '//res.wx.qq.com/open/js/jweixin-1.6.0.js';
    console.log('WechatService.injectScript', src);

    return new Promise((resolve) => {
      let timeout;

      const onSuccess = () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        this.injected = true;
        resolve(true);
      };

      try {
        if (this.injected) {
          console.log('WechatService.injectScript', 'already injected');
          onSuccess();
          return;
        }

        if (!this.isWechat) {
          console.log('WechatService.injectScript', 'not wechat');
          onSuccess();
          return;
        }

        if (!window.WeixinJSBridge || !window.WeixinJSBridge.invoke) {
          document.addEventListener('WeixinJSBridgeReady', onSuccess, false);
          // timeout = setTimeout(() => {
          //   resolve(false)
          // }, 5000)
        } else {
          onSuccess();
        }

        // const head = document.head || document.getElementsByTagName('head')[0]
        // postscribe(head, `<script src='${src}'></script>`)

        // let script = document.createElement('script')
        // script.async = true
        // script.src = src
        // let s = document.getElementsByTagName('script')[0]
        // s.parentNode.insertBefore(script, s)
        promiseInjectScript(src, { defer: true });
      } catch (err) {
        console.log('WechatService.injectScript', 'failed', err.message);
        resolve(false);
      }
    });
  }

  updateConfig = () => {
    return new Promise((resolve) => {
      if (!this.isWechat) {
        resolve();
        return;
      }

      const configUrl = `${import.meta.env.WECHAT_API}`;

      if (
        this.lastUrl === encodeURIComponent(window.location.href.split('#')[0])
      ) {
        resolve();
        return;
      }

      this.lastUrl = encodeURIComponent(window.location.href.split('#')[0]);

      const requestUrl = `${configUrl}?url=${this.lastUrl}`;

      window
        .fetch(requestUrl)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          return this.handleConfigSuccess(json);
        })
        .then(() => {
          resolve();
        });
    });
  };

  handleConfigSuccess = (config) => {
    return new Promise((resolve) => {
      if (!window.wx) {
        return resolve();
      }

      window.wx.config({
        debug: this.debugEnabled,
        appId: config.appId,
        timestamp: config.timestamp,
        nonceStr: config.nonceStr,
        signature: config.signature,
        jsApiList: [
          'checkJsApi',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'hideMenuItems',
          'showMenuItems',
        ],
      });
      window.wx.ready(() => {
        resolve();
      });
    });
  };

  enableShare = () => {
    return new Promise((resolve) => {
      if (!this.isWechat) {
        resolve();
        return;
      }

      window.wx.showMenuItems({
        menuList: [
          'menuItem:share:appMessage',
          'menuItem:share:timeline',
          'menuItem:share:qq',
          'menuItem:share:weiboApp',
          'menuItem:share:facebook',
        ],
      });
      resolve();
    });
  };

  disableShare = () => {
    // if (!window.wx) return
    window.wx.hideMenuItems({
      menuList: [
        'menuItem:share:appMessage',
        'menuItem:share:timeline',
        'menuItem:share:qq',
        'menuItem:share:weiboApp',
        'menuItem:share:facebook',
      ],
    });
  };

  setLink = (path, title = '', desc = '', moments = '', shareImg) => {
    const base = window.location.origin;
    const link = `${base}${path}`;
    const img = `${base}/${shareImg}`;

    if (!this.isWechat) {
      return;
    }

    window.wx.onMenuShareTimeline({
      link,
      title: moments,
      imgUrl: img,
      success: () => {
        // AnalyticsService.sendEvent('share', 'onMenuShareTimeline')
      },
    });

    window.wx.onMenuShareAppMessage({
      link,
      title,
      desc,
      imgUrl: img,
      success: () => {
        // AnalyticsService.sendEvent('share', 'onMenuShareAppMessage')
      },
    });
  };

  setDefaultLink = () => {
    // prettier-ignore
    return this.setLink(
			"",
			"wechat.generic.title",
			"wechat.generic.desc",
			"wechat.generic.moments",
			"favicon.jpg",
		);
  };
}

export default new WechatService();
