import styles from './TheMenu.module.sass';
import { useEffect, useRef, useState } from 'react';
import VirtualScroll from 'virtual-scroll';
import { LogEase } from '@/helpers/LogEase';
import gsap from 'gsap';
import Env from '@/helpers/Env';
import { useRaf } from '@/hooks/useRaf';
import classNames from 'classnames';
import { useAppStore } from '@/stores/app';
import { hover, click, menu2 } from '@/sounds';

// function getContrastYIQ(hexcolor) {
//   var r = parseInt(hexcolor.substring(1, 3), 16);
//   var g = parseInt(hexcolor.substring(3, 5), 16);
//   var b = parseInt(hexcolor.substring(5, 7), 16);
//   var yiq = (r * 299 + g * 587 + b * 114) / 1000;
//   return yiq >= 128 ? 'black' : 'white';
// }

export const TheMenu = ({ projects, ...props }) => {
  const setShowMenu = useAppStore((state) => state.setShowMenu);

  useEffect(() => {
    const onKeyDown = function (evt) {
      switch (evt.key) {
        case 'Escape': {
          setShowMenu(false);
          break;
        }

        default: {
          break;
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  // ****************************************
  // SHOW
  // ****************************************c
  const showMenu = useAppStore((state) => state.showMenu);

  const refRoot = useRef();
  const refBg = useRef();
  const refPivot = useRef();

  const animateIn = function () {
    // console.log('animateIn');
    let tl = new gsap.timeline();
    tl.add('reset');
    tl.set(refBg.current, { opacity: 0 }, 'reset');
    tl.set(refPivot.current, { rotation: 10, opacity: 1 }, 'reset');

    tl.set(refRoot.current, { opacity: 1 });

    tl.add('start');
    tl.to(
      refBg.current,
      {
        opacity: 1,
        duration: 0.2,
        ease: 'power2.out',
      },
      'start'
    );
    tl.to(
      refPivot.current,
      {
        rotation: 0,
        duration: 0.2,
        ease: 'power2.out',
      },
      'start'
    );
  };
  const animateOut = function () {
    let tl = new gsap.timeline();
    tl.to(
      refBg.current,
      {
        opacity: 0,
        duration: 0.2,
        ease: 'power2.out',
      },
      'start'
    );
    tl.to(
      refPivot.current,
      {
        rotation: -10,
        opacity: 0,
        duration: 0.2,
        ease: 'power2.out',
      },
      'start'
    );
  };

  const [project, setProject] = useState(null);

  useEffect(() => {
    if (showMenu) {
      animateIn();
      menu2.play();
    } else {
      animateOut();
    }
  }, [showMenu]);

  // ****************************************
  // SCROLL
  // ****************************************
  const scroll = useRef(new LogEase({ ease: 0.1 }));

  useEffect(() => {
    const scroller = new VirtualScroll();
    scroller.on((evt) => {
      if (!showMenu) return;
      gsap.killTweensOf(scroll.current);
      scroll.current.to -= evt.deltaY / (Env.mobile ? 50 : 100);
      scroll.current.to = Math.max(scroll.current.to, 0);
      scroll.current.to = Math.min(
        scroll.current.to,
        projects.length * 1.45 - (Env.mobile ? 10 : 5)
      );
    });

    return () => {
      gsap.killTweensOf(scroll.current);
      scroller.destroy();
    };
  }, [showMenu]);

  const refProjects = useRef();
  useRaf(() => {
    scroll.current.process();
    refProjects.current.style.transform = `rotate(${scroll.current.current}deg)`;
  });

  // ****************************************
  // HOVER
  // ****************************************
  const [hovering, setHovering] = useState(null);
  useEffect(() => {
    if (hovering) {
      hover.play();
    }
  }, [hovering]);

  // ****************************************
  // BG
  // ****************************************

  return (
    // <div className={styles.menu}>
    //   {/* <div
    //     className={styles.bg}
    //     style={{ '--color': hovering?.project.color || '#F40000' }}
    //   /> */}
    //   <div className={styles.panel}>

    //   </div>
    // </div>

    <div
      className={styles.menu}
      ref={refRoot}
      style={{ pointerEvents: showMenu ? 'auto' : 'none' }}
    >
      <div
        className={styles.bg}
        ref={refBg}
        onClick={() => {
          click.play();
          props.onClose?.();
        }}
      />
      <div className={styles.offset}>
        <div
          className={styles.pivot}
          ref={refPivot}
        >
          <ul
            className={styles.projects}
            ref={refProjects}
            onMouseLeave={() => {
              setHovering(null);
              props.onHover?.(0);
            }}
          >
            <li className={classNames(styles.project, styles.title)}>
              <div className={styles.project__label}>
                <div className={styles.stroke}>Projects</div>
                <div className={styles.solid}>Projects</div>
              </div>
            </li>
            {projects.map((project, idx) => (
              <li
                className={classNames(styles.project)}
                key={`${project.title}`}
                style={{ '--color': project.color }}
                onMouseEnter={() => {
                  setHovering({ project, idx });
                  props.onHover?.(idx);
                }}
                onClick={() => {
                  click.play();
                  props.onSelect?.(idx);
                }}
              >
                <div className={styles.project__label}>
                  <div
                    className={styles.project__bgBorder}
                    style={{ transform: project.bg.transform }}
                  />
                  <div
                    className={styles.project__bgSolid}
                    style={{
                      transform: project.bg.transform,
                      // background: hovering?.idx == idx ? project.color : null,
                    }}
                  />
                  <div
                    className={styles.project__bgHover}
                    style={{
                      transform: project.bg.transform,
                      opacity: hovering?.idx == idx ? 1 : 0,
                    }}
                  >
                    <div
                      className={styles.project__bgHoverFill}
                      style={{
                        background: project.color,
                      }}
                    />
                  </div>
                  <button
                    className={styles.project__button}
                    style={{
                      color: hovering?.idx == idx ? project.theme : null,
                    }}
                  >
                    {project.title}
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
