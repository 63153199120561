import {
  MeshBasicMaterial,
  MeshToonMaterial,
  TextureLoader,
  DoubleSide,
  FrontSide,
  BackSide,
} from 'three';

export const white = new MeshBasicMaterial({ color: 'white' });

export const black = new MeshBasicMaterial({ color: 'black' });

export const blackNoDepth = new MeshBasicMaterial({
  color: 'black',
  depthTest: false,
});

export const blackTransparentNoDepth = new MeshBasicMaterial({
  color: 'black',
});
blackTransparentNoDepth.transparent = true;
blackTransparentNoDepth.depthTest = false;

const textureLoader = new TextureLoader();
export const gradientMap = textureLoader.load('/assets/images/threeTone.jpg');
export const toonBlack = new MeshToonMaterial({
  color: '#666',
  gradientMap,
});

export const toonBlackNoDepth = new MeshToonMaterial({
  color: '#666',
  gradientMap,
  depthTest: false,
});
