import { useThree } from '@react-three/fiber';
import { Flex, Box } from '@react-three/flex';
import { Mask } from '@react-three/drei';
import { useState } from 'react';
import { OutlineLetter } from './OutlineLetter';
import { motion } from 'framer-motion-3d';
import * as basicMaterials from '@/webgl/materials';
export const OutlineText = ({
  children,
  margin = -1,
  maskOffset = 0,
  justifyContent = 'flex-start',
  ...props
}) => {
  const [size, setSize] = useState({
    width: 500,
    height: 75,
  });

  const { size: screenSize } = useThree();

  const mask = {
    hidden: { x: -size.width },
    enter: {
      x: 0,
      transition: {
        type: 'tween',
        duration: 0.1,
        ease: 'easeOut',
      },
    },
    exit: {
      x: size.width,
      transition: {
        type: 'tween',
        duration: 0.1,
        ease: 'easeOut',
      },
    },
  };

  return (
    <group onClick={props.onClick}>
      <Flex
        flexDirection="row"
        justifyContent={justifyContent}
        alignItems="flex-end"
        position={[props.fontSize * 0.8, 0, 0]}
        onReflow={(totalWidth, totalHeight) => {
          let w = totalWidth + 30;
          if (size.width != w || size.height != totalHeight) {
            setSize({ width: w, height: totalHeight });
          }
        }}
      >
        <Box
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {props.text.split('').map((letter, idx) => (
            <Box
              margin={margin}
              key={`${idx}-${letter}`}
            >
              <OutlineLetter
                {...props}
                letter={letter}
                idx={idx}
                maskId={props.maskId}
              />
            </Box>
          ))}

          {children}
        </Box>
      </Flex>

      {/* <mesh>
        <circleGeometry args={[2]} />
        <primitive attach="material" object={basicMaterials.black} />
      </mesh> */}

      {/* <mesh
        scale={[size.width, size.height, 1]}
        position={[size.width * 0.5, size.height * 0.5, 10]}
        visible={false}
        onPointerOut={props.onPointerOut}
      >
        <planeGeometry args={[1, 1]} />

      </mesh> */}

      <motion.mesh
        variants={mask}
        initial="hidden"
        animate="enter"
        exit="exit"
      >
        <Mask
          id={10}
          scale={[size.width * 1.1, size.height, 1]}
          position={[
            size.width * (justifyContent == 'flex-start' ? 0.5 : 0),
            size.height * 0.5,
            1,
          ]}
          // colorWrite
          // depthWrite
        >
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial
            transparent={true}
            opacity={1}
          />
        </Mask>
      </motion.mesh>
    </group>
  );
};
