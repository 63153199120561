import { DoubleSide, MathUtils } from 'three';
import { MaterialLines } from '@/webgl/materials/MaterialLines';
import { MaterialDots } from '@/webgl/materials/MaterialDots';
import { Sky } from '@/webgl/objects/Sky';
import { useControls } from 'leva';
import * as basicMaterials from '@/webgl/materials';
export const Ground = ({
  size = 100,
  shape = 'sphere',
  pattern = 'lines',
  showOutline = true,
  showSky = true,
  groundOutlineScale = 1.6,
  ...props
}) => {
  return (
    <group name="ground">
      {showSky && (
        <Sky
          {...props}
          size={size * groundOutlineScale}
          shape={shape}
          pattern={pattern}
        />
      )}

      {showOutline && (
        <mesh
          position={[0, -0.001, 0]}
          rotation={[MathUtils.degToRad(-90), 0, 0]}
          scale={groundOutlineScale}
        >
          <planeGeometry args={[size * 2, size * 2]} />
          <primitive
            attach="material"
            object={basicMaterials.white}
          />
        </mesh>
      )}

      <mesh
        position={[0, 0, 0]}
        rotation={[MathUtils.degToRad(-90), 0, 0]}
      >
        <planeGeometry args={[size, size]} />
        {/* <meshBasicMaterial color="red" /> */}
        <primitive
          attach="material"
          object={basicMaterials.toonBlack}
        />
      </mesh>
    </group>
  );
};
