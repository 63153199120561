import { useState, useContext, useMemo, useEffect } from 'react';

import { useFrame, useThree } from '@react-three/fiber';
import { A11y } from '@react-three/a11y';
import { Ring, Circle, OrthographicCamera } from '@react-three/drei';
import { Flex, Box } from '@react-three/flex';
import { MathUtils } from 'three';
import { AnimatePresence } from 'framer-motion';

import { OutlineText } from './OutlineText';
import { CameraContext } from '@/webgl/utils/CameraContext';
import { ProjectMenu } from './ProjectMenu';
import { MenuButton } from './MenuButton';
import { MuteButton } from './MuteButton';
import { ScrollButton } from './ScrollButton';
import { FollowCursor } from '@/webgl/controls/FollowCursor';
import { useScreenDistance } from '@/webgl/hooks/useScreenDistance';
import Env from '@/helpers/Env';
import { useAppStore } from '@/stores/app';
import { bgm } from '@/sounds';

export const Gui = ({
  projects,
  scroll,
  enabled = true,
  maskId = 10,
  ...props
}) => {
  const { size } = useThree();

  const loaded = useAppStore((state) => state.loaded);
  const audioUnlocked = useAppStore((state) => state.audioUnlocked);
  const watchHover = useAppStore((state) => state.watchHover);
  const watch = useAppStore((state) => state.watch);
  const showMenu = useAppStore((state) => state.showMenu);

  const refCamera = useContext(CameraContext);

  const [hovering, setHovering] = useState(null);

  const [project, setProject] = useState(null);

  useFrame(() => {
    let y = scroll.current;

    // Hide title in between transitions
    let mod = y % 1;
    if (mod > 0.1 && mod < 0.3) {
      if (project != null) setProject(null);
      return;
    }

    // Set project
    let pIdx = (Math.round(y + 0.25) - 1) % projects.length;
    let p = projects[pIdx];
    if (project == null || (project && p && project?.id != p?.id)) {
      setProject(p);
    }
  });

  const screenDistance = useScreenDistance();

  const offset = useMemo(() => (Env.mobile ? 15 : 30), []);

  // **************************************************
  // SCROLL CTA
  // **************************************************
  // const [showScrollCta, setShowScrollCta] = useState(false);
  // useEffect(() => {
  // }, []);

  return (
    <>
      <OrthographicCamera
        ref={refCamera}
        makeDefault
        position-z={screenDistance}
        near={0}
        far={screenDistance * 2}
      />
      <group
        name="Gui"
        position={[0, 0, 0]}
      >
        <group
          name="BottomLeft"
          position={[
            size.width * -0.5 + offset,
            size.height * -0.5 + offset * 1.8,
            0,
          ]}
          // scale={1.1}
        >
          <group position={[0, 0, 0]}>
            {loaded && enabled && project && (
              <group>
                <OutlineText
                  fontSize={Env.mobile ? 18 : 36}
                  text={project.title}
                  key={project.title}
                  maskId={maskId}
                  lockCase={project.lockCase}
                  // onPointerOut={() => setHovering(null)}
                />

                {project.client && (
                  <A11y role={project.client?.link ? 'link' : 'content'}>
                    <group
                      position={[0, Env.mobile ? -20 : -40, 0]}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        // console.log('test', project.client.link);
                        if (!project.client?.link) return;
                        window.open(project.client.link, '_blank');
                      }}
                    >
                      <OutlineText
                        fontSize={Env.mobile ? 24 * 0.55 : 24}
                        text={project.client?.label}
                        hasOutline={false}
                        lockCase={true}
                        margin={-0.25}
                        rotationAmount={0}
                      />
                    </group>
                  </A11y>
                )}
              </group>
            )}

            <group
              position={[Env.mobile ? 20 : 30, Env.mobile ? 16 : 36, 0]}
              rotation={[0, 0, MathUtils.degToRad(28)]}
              scale={Env.mobile ? 0.55 : 1}
              onPointerOver={(evt) => {
                if (hovering == null) {
                  setHovering(evt.pageX);
                }
              }}
              onPointerMove={(evt) => {
                if (hovering == null) {
                  setHovering(evt.pageX);
                }
              }}
              onPointerOut={(evt) => {
                setHovering(null);
              }}
            >
              {
                <AnimatePresence>
                  {loaded && enabled && project && !showMenu && (
                    <ProjectMenu
                      x={hovering}
                      project={project}
                      maskId={maskId}
                      key={project.title}
                      show={loaded && enabled && project}
                    />
                  )}
                </AnimatePresence>
              }

              {/* <Circle
                args={[150, 20, 0, Math.PI * 2]}
                position={[0, 0, 5]}
                rotation={[0, 0, MathUtils.degToRad(-28 - 30)]}
                // onPointerOut={props.onPointerOut}
                visible={true}
              >
                <meshBasicMaterial color={'red'} />
              </Circle> */}

              {/* <Ring
                args={[90, 100, 32]}
                position={[0, 0, 0]}
                rotation={[0, 0, MathUtils.degToRad(-28 - 30)]}
                // onPointerOut={props.onPointerOut}
                visible={true}
              >
                <meshBasicMaterial color={'white'} />
              </Ring> */}
            </group>
          </group>
        </group>

        <group
          name="TopRight"
          position={[size.width * 0.5 - offset, size.height * 0.5 - offset, 0]}
        >
          <group
            scale={Env.mobile ? 0.75 : 1}
            position={[0, Env.mobile ? -5 : 0, 0]}
          >
            <MenuButton />
          </group>

          <group
            scale={Env.mobile ? 0.75 : 1}
            position={[0, Env.mobile ? -70 : -80, 0]}
          >
            <MuteButton />
          </group>
        </group>

        <group
          name="BottomRight"
          position={[
            size.width * 0.5 - offset,
            size.height * -0.5 + offset * 0.5,
            0,
          ]}
        >
          {/* <group visible={project && project.id == 'intro'}>
            <group position={[-130, 0, 0]}>
              <OutlineText
                fontSize={14}
                text={'SCRoLL DoWN'}
                hasOutline={false}
                lockCase={true}
                margin={-1}
                rotationAmount={0}
              />
            </group>
            <ScrollButton />
          </group> */}

          {/* <Flex
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            {project &&
              project.meta.map((meta, i) => (
                <Box key={`${project.id}_${i}`}>
                  
                </Box>
              ))}
          </Flex> */}

          {/* {project && project.client && (
            <A11y role={project.client.link ? 'link' : 'content'}>
              <group
                onClick={(evt) => {
                  evt.stopPropagation();
                  // console.log('test', project.client.link);
                  if (!project.client.link) return;
                  window.open(project.client.link, '_blank');
                }}
              >
                <OutlineText
                  fontSize={20}
                  text={project.client.label}
                  hasOutline={false}
                  lockCase={true}
                  margin={-1}
                  rotationAmount={0}
                  justifyContent="flex-end"
                />
              </group>
            </A11y>
          )} */}
        </group>

        <FollowCursor ease={0.15}>
          <AnimatePresence>
            {!Env.mobile && !audioUnlocked && (
              <group position={[-140, 0, 0]}>
                <OutlineText
                  fontSize={20}
                  text={'Enable Audio'}
                  hasOutline={false}
                  // lockCase={true}
                  margin={-1}
                  rotationAmount={0}
                />
              </group>
            )}

            {/* {audioUnlocked &&
              !watch &&
              project &&
              watchHover &&
              project.id == watchHover.id && (
                <group position={[-75, 0, 0]}>
                  <OutlineText
                    fontSize={20}
                    text={'Watch'}
                    hasOutline={false}
                    // lockCase={true}
                    margin={-1}
                    rotationAmount={0}
                  />
                </group>
              )} */}
          </AnimatePresence>
        </FollowCursor>
      </group>
    </>
  );
};
