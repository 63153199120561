import { useState } from 'react';
import { useFrame } from '@react-three/fiber';

import { map } from '@/helpers/MathUtils';
import { inOutQuad } from '@/helpers/Ease';

const offset = 0.5;

export const ScrollProgress = ({
  children,
  scroll,
  idx = 0,
  numScenes = 1,
  numProjects = 1,
}) => {
  const [active, setActive] = useState(false);
  const [play, setPlay] = useState(false);
  const [progress, setProgress] = useState(0);
  const [mask, setMask] = useState(0);
  const [projectIdx, setProjectIdx] = useState(0);

  useFrame(() => {
    let y = scroll.current;
    let p = (y - idx) % numScenes;

    // ANIMATION PROGRESS
    setProgress(map(p, 0, 1 + offset, 0, 1, true));

    // ACTIVE
    setActive(p >= 0 && p < 1 + offset);

    // PLAY
    setPlay(p >= offset / 2 && p < 1 + offset / 2);

    // MASK
    let m;
    if (p < 0) {
      m = -1;
    } else if (p >= 0 && p < offset) {
      m = map(p, 0, offset, -1, 0, true);
    } else if (p >= offset && p < 1) {
      m = 0;
    } else if (p >= 1 && p < 1 + offset) {
      m = map(p, 1.0, 1 + offset, 0, 1, true);
    } else if (p >= 1 + offset) {
      m = 1;
    }
    setMask(m);

    // PROJECT ID
    let projectPage = Math.floor((y - idx) / numProjects);
    let scenePage = Math.floor((y - idx) / numScenes) % numProjects;
    let pIdx = (scenePage * numScenes + idx) % numProjects;
    setProjectIdx(pIdx);
  });

  return (
    <group>
      {children({ progress, maskProgress: mask, active, play, projectIdx })}
    </group>
  );
};
