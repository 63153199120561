import { Suspense, useMemo, useEffect } from 'react';
import { MaterialVideo } from './MaterialVideo2';
import { MaterialImage } from './MaterialImage';
import { useVideoTexture } from '@react-three/drei';
// import { RepeatWrapping } from 'three';
import { useAppStore } from '@/stores/app';
import * as basicMaterials from '@/webgl/materials';
export const MaterialProjectPoster = ({ project, ...props }) => {
  return (
    <>
      {project.poster?.localPath ? (
        <MaterialImage
          src={project.poster.localPath}
          key={project.poster.localPath}
          {...props}
        />
      ) : (
        <primitive
          attach="material"
          object={basicMaterials.black}
        />
      )}
    </>
  );
};

export const MaterialProject = ({ project, enabled, ...props }) => {
  const loaded = useAppStore((state) => state.loaded);

  // const mode = useMemo(() => {
  //   let _mode = 'black';
  //   if (project.video && project.video.localPath && enabled) {
  //     _mode = 'video';
  //   } else if (project.poster && project.poster.localPath) {
  //     _mode = 'poster';
  //   }
  //   // _mode = 'poster';
  //   return _mode;
  // }, [enabled, project.video, project.poster]);

  return (
    <>
      <primitive
        attach="material"
        object={basicMaterials.black}
      />
      {
        <Suspense>
          <MaterialProjectPoster
            project={project}
            {...props}
          />
        </Suspense>
      }
      {project.video?.localPath && enabled && (
        <Suspense>
          <MaterialVideo
            src={project.video.localPath}
            key={project.video.localPath}
            {...props}
          />
        </Suspense>
      )}
    </>
  );
};
