import { create } from 'zustand';
import { Howler } from 'howler';
import Env from '@/helpers/Env';

export const useAppStore = create((set) => ({
  // ****************************************
  // LOADED
  // ****************************************
  loaded: false,
  setLoaded: () =>
    set((state) => {
      return { loaded: true };
    }),

  // ****************************************
  // CAN START
  // ****************************************
  canStart: !Env.wechat,
  setCanStart: (val) =>
    set((state) => {
      return { canStart: true };
    }),

  // ****************************************
  // AUDIO UNLOCKED
  // ****************************************
  audioUnlocked: false,
  setAudioUnlocked: (val) =>
    set((state) => {
      return { audioUnlocked: true };
    }),

  // ****************************************
  // MUTED
  // ****************************************
  muted: false,
  setMuted: (val) =>
    set((state) => {
      Howler.mute(val);
      return { muted: val };
    }),
  toggleMuted: () =>
    set((state) => {
      let m = !state.muted;
      Howler.mute(m);
      return { muted: m };
    }),

  // ****************************************
  // MENU
  // ****************************************
  showMenu: false,
  setShowMenu: (val) =>
    set((state) => {
      return { showMenu: val };
    }),
  toggleShowMenu: () =>
    set((state) => {
      return { showMenu: !state.showMenu };
    }),

  // ****************************************
  // WATCH
  // ****************************************
  watch: null,
  setWatch: (val) =>
    set((state) => {
      if (!state.audioUnlocked) return {};
      if (val) {
        Howler.mute(true);
      } else if (!state.muted) {
        Howler.mute(false);
      }
      console.log('setWatch', val);
      return { watch: val };
    }),
  watchHover: null,
  setWatchHover: (val) =>
    set((state) => {
      if (!state.audioUnlocked) return {};
      // console.log('setWatchHover', val);
      return { watchHover: val };
    }),
}));
