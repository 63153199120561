import { useRef, useEffect, useCallback } from 'react';
import { useThree } from '@react-three/fiber';
import gsap from 'gsap';
import Env from '@/helpers/Env';
import { useAppStore } from '@/stores/app';

export const HoldZoomControls = ({ children, from, to, ...props }) => {
  const watch = useAppStore((state) => state.watch);

  const refRoot = useRef();

  const { gl, camera } = useThree();

  useEffect(() => {
    if (Env.mobile) return;
    const updateCam = () => {
      camera.updateProjectionMatrix();
    };

    if (watch) {
      gsap.to(camera, { fov: to, onUpdate: updateCam });
    } else {
      gsap.to(camera, { fov: from, onUpdate: updateCam });
    }
  }, [camera, watch, from, to]);

  // const touching = useRef(false);
  // useEffect(() => {
  //   if (Env.mobile) return;
  //   if (watch) return;

  //   const fromFov = camera.fov;
  //   const toFov = fromFov + fov;

  //   const onTouchStart = (evt) => {
  //     touching.current = true;
  //     gsap.to(camera, { fov: toFov, duration: 2, onUpdate: updateCam });
  //   };

  //   const onTouchEnd = (evt) => {
  //     touching.current = false;
  //     gsap.to(camera, { fov: fromFov, onUpdate: updateCam });
  //   };

  //   gl.domElement.addEventListener('mousedown', onTouchStart);
  //   gl.domElement.addEventListener('touchstart', onTouchStart);
  //   gl.domElement.addEventListener('mouseup', onTouchEnd);
  //   gl.domElement.addEventListener('touchend', onTouchEnd);

  //   return () => {
  //     gl.domElement.removeEventListener('mousedown', onTouchStart);
  //     gl.domElement.removeEventListener('touchstart', onTouchStart);
  //     gl.domElement.removeEventListener('mouseup', onTouchEnd);
  //     gl.domElement.removeEventListener('touchend', onTouchEnd);
  //   };
  // }, [camera, fov, watch]);

  return <group ref={refRoot}>{children}</group>;
};
