
varying vec2 vUv;
uniform vec3 uColor;
uniform float uTime;
uniform float uSpeed;
uniform float uFreq;
uniform float uGap;

void main() {
  float strength = mod((vUv.y + uTime * uSpeed) * uFreq, 1.0) ;
  strength = step(uGap, strength);
  gl_FragColor = vec4(uColor.x * strength, uColor.y * strength, uColor.z * strength, 1.0);
}