import styles from './VideoOverlay.module.sass';
import { useAppStore } from '@/stores/app';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';

export const VideoOverlay = () => {
  const project = useAppStore((state) => state.watch);
  const setWatch = useAppStore((state) => state.setWatch);

  // console.log('project', project?.id, project?.youtubeId);

  useEffect(() => {
    const onKeyDown = function (evt) {
      switch (evt.key) {
        case 'Escape': {
          setWatch(null);
          break;
        }

        default: {
          break;
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <AnimatePresence>
      {project && (
        <div className={styles.videoOverlay}>
          <motion.div
            className={styles.underlay}
            onClick={() => {
              setWatch(null);
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <motion.div
            className={styles.video}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
          >
            <iframe
              key={project._uid}
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${project.youtubeId}?autoplay=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={project.title}
            />
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};
