import { useGLTF, PerspectiveCamera, OrbitControls } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import { useRef, useEffect, useContext, useState } from 'react';
import {
  MathUtils,
  BackSide,
  FrontSide,
  DoubleSide,
  MeshBasicMaterial,
} from 'three';
import { CameraContext } from '@/webgl/utils/CameraContext';
import { useAnimationProgress } from '@/webgl/hooks/useAnimationProgress';
import { useAnimations } from '@/webgl/hooks/useAnimations';
import { Sky } from '@/webgl/objects/Sky';
import { TextRings } from '@/webgl/objects/TextRings';
import { Select } from '@react-three/postprocessing';
import { MaterialProject } from '@/webgl/materials/MaterialProject';
import { MaterialOutline } from '@/webgl/materials/MaterialOutline';
import { HoverControls } from '@/webgl/controls/HoverControls';
import { HoldZoomControls } from '@/webgl/controls/HoldZoomControls';
import { MobileDistance } from '@/webgl/utils/MobileDistance';
import { map } from '@/helpers/MathUtils';
import { SlowMo } from 'gsap/EasePack';
import * as basicMaterials from '@/webgl/materials';
import { useAppStore } from '@/stores/app';
import { WatchButton } from '@/webgl/utils/WatchButton';
import Env from '@/helpers/Env';

// const url = 'assets/models/blimp/blimp.glb';
const url = 'assets/models-compressed/blimp/blimp-o.glb';

const ease = SlowMo.config(0.1, 0.1, true);

export const Blimp = ({ project, progress, enabled, ...props }) => {
  const { size } = useThree();

  const color = project.color;

  // CAMERA
  const refCamera = useContext(CameraContext);

  // GLTF
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(url);

  // ANIMATION
  useAnimationProgress(group, animations[0], progress);

  // useAnimations(group, animations[0]);

  // useFrame(() => {
  //   let a = 0;
  //   let from = 0.18;
  //   let to = 0.82;
  //   if (progress >= from && progress <= to) {
  //     a = map(progress, from, to, 0, 1);
  //   }

  //   let b = ease(a);
  //   let fov = map(b, 0, 1, 20, 2.7);

  //   refCamera.current.fov = fov;
  //   refCamera.current.updateProjectionMatrix();
  // });

  const [pattern, setPattern] = useState('lines');
  useEffect(() => {
    setPattern(Math.random() > 0.35 ? 'lines' : 'dots');
  }, [project]);

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
    >
      <directionalLight
        intensity={(Env.mobile ? 0.8 : 1) * 0.03}
        position={[-1, 10, 1]}
      />

      <group rotation={[0, MathUtils.degToRad(90), 0]}>
        <Sky
          size={500}
          color={color}
          speed={0.004}
          freq={120}
          sharpness={50}
          pattern={'lines'}
        />
      </group>
      {/* <TextRings
        position={[0, -9, 0]}
        project={project}
        radius={500}
        height={5}
        repeat={Math.ceil(11 / (project.tags?.length || 1))}
        speed={0.0002}
        speedMultiplier={0.25}
        enabled={enabled}
      /> */}

      <group name="Scene">
        <Select enabled>
          <mesh
            name="city-mesh"
            geometry={nodes['city-mesh'].geometry}
          >
            <primitive
              attach="material"
              object={basicMaterials.toonBlack}
            />
          </mesh>

          <WatchButton
            project={project}
            enabled={enabled}
          >
            <group
              name="Blimp"
              position={[0, 0, 5.3811]}
            >
              <group
                name="BlimpPivot"
                position={[0, 67.73, 0]}
              >
                <mesh
                  name="BlimpBody"
                  geometry={nodes.BlimpBody.geometry}
                  position={[0, 0, 6.301]}
                >
                  <primitive
                    attach="material"
                    object={basicMaterials.toonBlack}
                  />
                </mesh>

                <mesh
                  name="Screen"
                  geometry={nodes.Screen.geometry}
                  position={[-4.5, -0.172, -5.601]}
                  rotation={[Math.PI, 0, Math.PI / 2]}
                >
                  <MaterialProject
                    enabled={enabled}
                    project={project}
                    depthTest={false}
                    side={FrontSide}
                  />
                </mesh>
              </group>
            </group>
          </WatchButton>
        </Select>

        <group
          name="CameraAnimator"
          position={[0, 1.187, -0.788]}
          rotation={[0, Math.PI / 2, 0]}
        >
          <HoverControls
            easeX={0.1}
            easeY={0.1}
            easeZ={0.1}
            posX={0}
            posY={0.1}
            posZ={1}
            rotX={0.05}
            rotY={0.2}
            rotZ={-0.0}
          >
            <MobileDistance distance={(size.height / size.width) * 10}>
              <HoldZoomControls
                from={20}
                to={20 - 6}
              >
                <PerspectiveCamera
                  name="Camera"
                  ref={refCamera}
                  makeDefault={true}
                  far={1000}
                  near={0.1}
                  fov={Env.mobile ? 25 : 20}
                  position={[0, 68.291, 47.608]}
                  rotation={[-0.052, 0, 0]}
                />
              </HoldZoomControls>
            </MobileDistance>
          </HoverControls>
        </group>
      </group>
    </group>
  );
};

useGLTF.preload(url);
