import { MathUtils } from 'three';
import { Arrow } from './Arrow';

export const HoverArrows = ({ color, ...props }) => {
  return (
    <group>
      <group name="Arrows">
        <Arrow
          scale={[14, 14, 14]}
          shapeScale={[1.2, 6, 1]}
          rotation={[0, 0, MathUtils.degToRad(30)]}
          color={color}
        />
        <Arrow
          scale={[12, 12, 12]}
          shapeScale={[0.5, 13, 1]}
          rotation={[0, 0, MathUtils.degToRad(24)]}
          color={color}
        />
        <Arrow
          scale={[12, 12, 12]}
          shapeScale={[1, 10, 1]}
          rotation={[0, 0, MathUtils.degToRad(18)]}
          color={color}
        />
        <Arrow
          scale={[12, 12, 12]}
          shapeScale={[2, 5, 1]}
          rotation={[0, 0, MathUtils.degToRad(-5)]}
          color={color}
        />
        <Arrow
          scale={[12, 12, 12]}
          shapeScale={[0.8, 12, 1]}
          rotation={[0, 0, MathUtils.degToRad(2)]}
          color={color}
        />
        <Arrow
          scale={[12, 12, 12]}
          shapeScale={[0.1, 20, 1]}
          rotation={[0, 0, MathUtils.degToRad(0)]}
          color={color}
        />
        <Arrow
          scale={[12, 12, 12]}
          shapeScale={[0.2, 14, 1]}
          rotation={[0, 0, MathUtils.degToRad(-5)]}
          color={color}
        />
        <Arrow
          scale={[12, 12, 12]}
          shapeScale={[2, 8, 1]}
          rotation={[0, 0, MathUtils.degToRad(-20)]}
          color={color}
        />
        <Arrow
          scale={[12, 12, 12]}
          shapeScale={[1, 12, 1]}
          rotation={[0, 0, MathUtils.degToRad(-20)]}
          color={color}
        />
      </group>

      <group>
        <Arrow
          name="ProjectMenu-Arrow-bottomRight"
          scale={[12, 12, 12]}
          shapeScale={[1, 12, 1]}
          rotation={[0, 0, MathUtils.degToRad(-44)]}
          color={color}
        />

        <Arrow
          scale={[14, 14, 14]}
          shapeScale={[1.2, 6, 1]}
          rotation={[0, 0, MathUtils.degToRad(-51)]}
          color={color}
        />
      </group>

      <group>
        <Arrow
          name="ProjectMenu-Arrow-bottomRight"
          scale={[12, 12, 12]}
          shapeScale={[1, 12, 1]}
          rotation={[0, 0, MathUtils.degToRad(-180)]}
          color={color}
        />

        <Arrow
          scale={[14, 14, 14]}
          shapeScale={[1.2, 6, 1]}
          rotation={[0, 0, MathUtils.degToRad(-195)]}
          color={color}
        />
      </group>
    </group>
  );
};
