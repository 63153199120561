import { useRef, useEffect, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { Stats, OrthographicCamera } from '@react-three/drei';
import { Perf } from 'r3f-perf';
import { getUrlBoolean } from '@/helpers/UrlParam';
import { Gui } from '@/webgl/gui';
import { Layer } from '@/webgl/utils/Layer';
import { LayerWithEffects } from '@/webgl/utils/LayerWithEffects';
import { Render } from '@/webgl/utils/Render';
import { ScrollProgress } from '@/webgl/utils/ScrollProgress';

// import { Loader } from '@/webgl/objects/Loader';
import { Intro } from '@/webgl/objects/Intro';
import { Roof } from '@/webgl/objects/Roof';
import { Shibuya } from '@/webgl/objects/Shibuya';
import { Subway } from '@/webgl/objects/Subway';
import { Blimp } from '@/webgl/objects/Blimp';
import { Picadilly } from '@/webgl/objects/Picadilly';
// import { Taxi } from '@/webgl/objects/Taxi';

import { TheMenuButton } from '@/components/TheMenuButton';
import { TheMenu } from '@/components/TheMenu/v1/TheMenu';
import { Loading } from '@/components/Loading';

import { AnimatePresence } from 'framer-motion';
import { ui } from '@/tunnels';
import VirtualScroll from 'virtual-scroll';
import { LogEase } from '@/helpers/LogEase';
import gsap from 'gsap';

import projects from '@/data/projects';
import Env from '@/helpers/Env';
import { suspend } from 'suspend-react';
import { PromiseTimeout } from '@/helpers/PromiseTimeout';
import { useAppStore } from '@/stores/app';
import { bgm } from '@/sounds';

const numScenes = 5;
const numProjects = projects.length;

// Deep link
let id = window.location.pathname;
let p = projects.find((p) => p.id == id.replace('/', ''));
let pi = projects.indexOf(p) > 0 ? projects.indexOf(p) : 0;

const offset = -numScenes - 1;
const fromY = offset + numProjects * 3 * 100;
const toY = fromY + (1 - offset - 0.0001) + pi;

export function Scenes(props) {
  // ****************************************
  // SCROLL
  // ****************************************

  const scroll = useRef(new LogEase({ current: fromY, to: fromY, ease: 0.1 }));
  const menuOffset = useRef(new LogEase({ ease: 0.1 }));
  const y = useRef(fromY);

  const loaded = useAppStore((state) => state.loaded);
  const setLoaded = useAppStore((state) => state.setLoaded);
  const canStart = useAppStore((state) => state.canStart);
  const setCanStart = useAppStore((state) => state.setCanStart);
  // const [canStart, setCanStart] = useState(!Env.wechat);

  // ANIMATE IN
  const animateIn = () => {
    bgm.play();

    gsap.killTweensOf(scroll.current);
    gsap.fromTo(
      scroll.current,
      {
        current: toY - 0.3,
        to: toY - 0.3,
      },
      {
        current: toY,
        to: toY,
        delay: 0,
        duration: 2,
        ease: 'power2.out',
      }
    );
  };

  useEffect(() => {
    if (loaded && canStart) {
      animateIn();
    }
  }, [loaded, canStart]);

  // LOAD
  useEffect(() => {
    async function load() {
      // PRELOAD
      // await gsap
      //   .fromTo(
      //     scroll.current,
      //     { current: fromY, to: toY },
      //     {
      //       current: toY,
      //       to: toY,
      //       duration: numScenes * (Env.mobile ? 1 : 0.5),
      //       ease: `steps(${numScenes * 2})`,
      //     }
      //   )
      //   .then();

      await PromiseTimeout(100);
      setLoaded();
      // if (!Env.wechat) {
      //   setCanStart(true);
      // }
    }
    load();
  }, []);

  const showMenu = useAppStore((state) => state.showMenu);
  const setShowMenu = useAppStore((state) => state.setShowMenu);

  useEffect(() => {
    if (!loaded) return;
    // SCROLL INTERACTION
    let snapTimeout;
    const doSnap = function (dir) {
      // if (!Env.mobile) return;
      // return;
      let to;
      if (dir > 0) {
        // up
        to = Math.floor(scroll.current.to);
      } else {
        // down
        to = Math.ceil(scroll.current.to);
      }
      let snapTween = gsap.to(scroll.current, {
        to: to,
        // current: to,
        duration: 0.85,
        ease: 'power2.in',
      });
    };

    // SCROLLER
    const scroller = new VirtualScroll();
    scroller.on((evt) => {
      if (showMenu) return;
      gsap.killTweensOf(scroll.current);
      let amount = evt.deltaY / (Env.mobile ? 1000 : 5000);
      let dir = Math.sign(amount);
      amount = Math.min(Math.abs(amount), 0.06);
      scroll.current.to -= amount * dir;

      clearTimeout(snapTimeout);
      snapTimeout = setTimeout(() => doSnap(dir), 200); // SNAP
    });

    return () => {
      clearTimeout(snapTimeout);
      gsap.killTweensOf(scroll.current);
      scroller.destroy();
    };
  }, [loaded, showMenu]);

  // UPDATE SCROLL POSITION
  useFrame((state) => {
    scroll.current.process();
    menuOffset.current.process();

    y.current = scroll.current.current + menuOffset.current.current;
  });

  // ****************************************
  // UPDATE URL
  // ****************************************
  let project = useRef(null);
  useFrame(() => {
    // return;
    if (Env.mobile) return;
    let idx = Math.floor(y.current - 0.25) % projects.length;

    if (project.current != projects[idx]) {
      project.current = projects[idx];

      switch (project.current.id) {
        case 'intro':
          window.history.replaceState('', 'Ethan Chiu', '/');
          break;
        default:
          window.history.replaceState(
            '',
            project.current.title,
            `/${project.current.id}`
          );
          break;
      }
    }
  });

  // ****************************************
  // ARROW KEYS
  // ****************************************
  useEffect(() => {
    const onKeyDown = function (evt) {
      switch (evt.key) {
        case 'ArrowUp':
        case 'ArrowLeft': {
          let y = Math.round(scroll.current.current - 1);
          scroll.current.to = y;
          break;
        }
        case 'ArrowDown':
        case 'ArrowRight': {
          let y = Math.round(scroll.current.current + 1);
          scroll.current.to = y;
          break;
        }
        default: {
          break;
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  // ****************************************
  // RENDER
  // ****************************************
  return (
    <>
      <ui.In>
        {/* <TheMenuButton
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        /> */}

        <TheMenu
          scroll={y}
          projects={projects}
          // onHover={(idx) => {
          //   if (!showMenu) return;
          //   let pIdx = Math.floor(scroll.current.current) % projects.length;
          //   menuOffset.current.set(idx - pIdx);
          // }}
          // onUnhover={() => {
          //   menuOffset.current.set(0);
          // }}
          onClose={() => {
            menuOffset.current.set(0);
            setShowMenu(false);
          }}
          onSelect={(idx) => {
            let pIdx =
              (Math.round(scroll.current.current + 0.25) - 1) % projects.length;
            const offset = idx - pIdx;
            const to = scroll.current.current + offset;

            menuOffset.current.set(0);
            scroll.current.set(to - 0.3);
            scroll.current.to = to;
            setShowMenu(false);
          }}
        />

        {/* <AnimatePresence>
          {(!loaded || !canStart) && (
            <Loading
              loaded={loaded}
              onClick={() => {
                
                setCanStart(true);
                // animateIn();
              }}
            />
          )}
        </AnimatePresence> */}
      </ui.In>

      {/* <ambientLight intensity={(Env.mobile ? 0.8 : 1) *1} /> */}
      <OrthographicCamera
        makeDefault
        position-z={1000}
        near={0}
      />

      {[...Array(numScenes)].map((_, i) => (
        <ScrollProgress
          scroll={y}
          idx={i}
          numScenes={numScenes}
          numProjects={numProjects}
          key={i}
        >
          {({ progress, maskProgress, projectIdx, active, play }) => (
            <>
              {/* {console.log(i, projectIdx, play)} */}
              <LayerWithEffects
                enabled={active}
                renderPriority={numScenes - i + 2}
                maskId={numScenes - i + 2}
                maskProgress={maskProgress}
              >
                {projectIdx == 0 ? (
                  <Intro
                    project={projects[projectIdx]}
                    progress={progress}
                    enabled={canStart && play}
                  />
                ) : (
                  <>
                    {i == 1 && (
                      <Roof
                        project={projects[projectIdx]}
                        progress={progress}
                        enabled={canStart && play}
                      />
                    )}
                    {i == 2 && (
                      <Subway
                        project={projects[projectIdx]}
                        progress={progress}
                        enabled={canStart && play}
                      />
                    )}
                    {i == 3 && (
                      <Shibuya
                        project={projects[projectIdx]}
                        progress={progress}
                        enabled={canStart && play}
                      />
                    )}
                    {i == 4 && (
                      <Blimp
                        project={projects[projectIdx]}
                        progress={progress}
                        enabled={canStart && play}
                      />
                    )}
                    {i == 0 && (
                      <Picadilly
                        project={projects[projectIdx]}
                        progress={progress}
                        enabled={canStart && play}
                      />
                    )}
                    {/* {i == 0 && (
                      <Taxi
                        project={projects[projectIdx]}
                        progress={progress}
                        enabled={canStart && play}
                      />
                    )} */}
                  </>
                )}
              </LayerWithEffects>
            </>
          )}
        </ScrollProgress>
      ))}

      {/* <grid.In>
        <div className="contents">
          <div>Index: {i}</div>
          <div>Progress: {progress.toFixed(3)}</div>
          <div>Mask: {maskProgress.toFixed(3)}</div>
          <div>Project: {projectIdx}</div>
        </div>
      </grid.In> */}

      <Layer renderPriority={2}>
        <ambientLight intensity={(Env.mobile ? 0.8 : 1) * 1} />
        <OrthographicCamera
          makeDefault
          position-z={1000}
          near={0}
        />

        <Gui
          projects={projects}
          scroll={y}
        />
      </Layer>

      <Render renderPriority={8} />
    </>
  );
}
