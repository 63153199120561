import { useEffect, useState, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import {
  RepeatWrapping,
  Texture,
  VideoTexture,
  NoColorSpace,
  SRGBColorSpace,
  LinearSRGBColorSpace,
  NearestFilter,
  LinearFilter,
  RGBAFormat,
} from 'three';

const video = document.querySelector('#video');
const texture = new VideoTexture(video);
texture.wrapS = texture.wrapT = RepeatWrapping;
// texture.magFilter = texture.minFilter = NearestFilter;
// texture.magFilter = texture.minFilter = LinearFilter;

export const MaterialVideo = ({ src, start = true, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const needsUpdate = useRef(false);
  const { gl } = useThree();

  useEffect(() => {
    setLoaded(false);
    texture.colorSpace = gl.outputColorSpace;
    texture.encoding = gl.outputEncoding;
    // texture.colorSpace = NoColorSpace;
    // texture.colorSpace = SRGBColorSpace;
    // texture.colorSpace = LinearSRGBColorSpace;
    texture.needsUpdate = true;

    const onLoaded = async () => {
      const aspect = texture.image.videoWidth / texture.image.videoHeight;
      const repeatX = aspect < 1 ? 3 : 1;
      texture.repeat.set(repeatX, 1);
      // texture.needsUpdate = true;
    };

    // texture.needsUpdate = true;
    video.addEventListener('loadedmetadata', onLoaded);
    video.src = src;

    const onFrame = () => {
      texture.needsUpdate = true;
      needsUpdate.current = true;
    };
    if (video.requestVideoFrameCallback) {
      video.requestVideoFrameCallback(onFrame);
    } else {
      video.addEventListener('play', onFrame, { once: true });
    }
    video.play();

    return () => {
      // video.src = null;
      video.removeEventListener('loadedmetadata', onLoaded);
      video.removeEventListener('play', onFrame, { once: true });
      // texture.needsUpdate = true;
    };
  }, [src]);

  useFrame(() => {
    if (needsUpdate.current && !loaded) {
      setLoaded(true);
    }
  });

  return (
    <>
      {loaded && (
        <>
          <meshBasicMaterial
            map={texture}
            {...props}
          />
        </>
      )}
    </>
  );
};
