import { useRef, useEffect } from 'react';
import gsap from 'gsap';

import { CustomEase } from 'gsap/CustomEase';
import { CustomWiggle } from 'gsap/CustomWiggle';
import { MathUtils } from 'three';

gsap.registerPlugin(CustomEase, CustomWiggle);
CustomWiggle.create('myWiggle', { wiggles: 5, type: 'linear' });

export const Wiggle = ({ children, ...props }) => {
  const refRoot = useRef();

  useEffect(() => {
    let tl = gsap.timeline();
    tl.add('start');
    tl.fromTo(
      refRoot.current.rotation,
      { z: MathUtils.degToRad(-0.1) },
      {
        z: MathUtils.degToRad(0.1),
        duration: `random(1, 2)`,
        ease: 'myWiggle',
        repeat: -1,
      },
      'start'
    );
    tl.fromTo(
      refRoot.current.position,
      { x: `random(-1, -2)` },
      {
        x: `random(1, 2)`,
        duration: `random(1, 2)`,
        ease: 'myWiggle',
        repeat: -1,
      },
      'start'
    );
  }, []);

  return <group ref={refRoot}>{children}</group>;
};
