import { useGLTF, PerspectiveCamera } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useRef, useEffect, useContext, useState } from 'react';
import { MathUtils, BackSide, FrontSide, DoubleSide } from 'three';
import { CameraContext } from '@/webgl/utils/CameraContext';
import { HoverControls } from '@/webgl/controls/HoverControls';
import { useAnimationProgress } from '@/webgl/hooks/useAnimationProgress';
import { useAnimations } from '@/webgl/hooks/useAnimations';
import { Sky } from '@/webgl/objects/Sky';
import { TextRings } from '@/webgl/objects/TextRings';
import { Select } from '@react-three/postprocessing';
import { MaterialProject } from '@/webgl/materials/MaterialProject';
import { HoldZoomControls } from '@/webgl/controls/HoldZoomControls';
import { MobileDistance } from '@/webgl/utils/MobileDistance';
import { MaterialOutline } from '@/webgl/materials/MaterialOutline';
import * as basicMaterials from '@/webgl/materials';
import { useAppStore } from '@/stores/app';
import { WatchButton } from '@/webgl/utils/WatchButton';
import Env from '@/helpers/Env';

// const url = 'assets/models/roof/roof-o.glb';
const url = 'assets/models-compressed/roof/roof-o.glb';

export const Roof = ({ project, progress, enabled, ...props }) => {
  const { size } = useThree();

  const color = project.color;

  // CAMERA
  const refCamera = useContext(CameraContext);

  // GLTF
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(url);

  // ANIMATION
  useAnimationProgress(group, animations[0], progress);

  // useAnimations(group, animations[0]);

  const [pattern, setPattern] = useState('lines');
  useEffect(() => {
    setPattern(Math.random() > 0.35 ? 'lines' : 'dots');
  }, [project]);

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
    >
      <directionalLight
        intensity={(Env.mobile ? 0.8 : 1) * 0.04}
        position={[-1, 1, 3]}
      />

      <group rotation={[0, MathUtils.degToRad(110), 0]}>
        <Sky
          size={500}
          color={color}
          speed={0.01}
          freq={80}
          rad={0.25}
          rep={200}
          sharpness={10.0}
          pattern={pattern}
        />
      </group>
      {/* <TextRings
        position={[0, 0, 0]}
        project={project}
        radius={512}
        height={10}
        lines={30}
        repeat={Math.ceil(13 / (project.tags?.length || 1))}
        enabled={enabled}
      /> */}

      <group name="Scene">
        <group
          name="CameraAnimator"
          position={[0, -36.095, 0]}
        >
          <group
            name="CameraPivot"
            position={[-25.694, 50.18, 38.758]}
            rotation={[0.285, -0.472, 0.403]}
          >
            <HoverControls
              easeX={0.1}
              easeY={0.1}
              easeZ={0.1}
              posX={0.2}
              posY={-2}
              posZ={5}
              rotX={0.1}
              rotY={0.0666}
              rotZ={-0.1}
            >
              <HoldZoomControls
                from={30}
                to={30 - 6}
              >
                <MobileDistance distance={(size.height / size.width) * 10}>
                  <PerspectiveCamera
                    ref={refCamera}
                    name="Camera"
                    makeDefault={true}
                    far={4000}
                    near={0.1}
                    fov={Env.mobile ? 36 : 30}
                  />
                </MobileDistance>
              </HoldZoomControls>
            </HoverControls>
          </group>
        </group>

        <HoverControls
          posX={0.0}
          posY={0.0}
          posZ={0.0}
          rotX={0}
          rotY={-0.15}
          rotZ={0}
        >
          <Select enabled>
            <mesh
              name="base"
              geometry={nodes.base.geometry}
              position={[0, 5.464, 0]}
            >
              <primitive
                attach="material"
                object={basicMaterials.toonBlack}
              />
            </mesh>
            <mesh
              name="frame"
              geometry={nodes.frame.geometry}
              position={[12.306, 41.464, 5.261]}
              rotation={[-Math.PI / 2, 0, -Math.PI]}
            >
              <primitive
                attach="material"
                object={basicMaterials.toonBlack}
              />
            </mesh>

            <WatchButton
              project={project}
              enabled={enabled}
            >
              <mesh
                name="screens"
                geometry={nodes.screens.geometry}
                position={[5.306, 51.464, 5.261]}
                onClick={() => {
                  // setHovering(project)
                }}
              >
                <MaterialProject
                  enabled={enabled}
                  project={project}
                  depthTest={true}
                  side={FrontSide}
                />
              </mesh>
            </WatchButton>
            <mesh
              name="bottom"
              geometry={nodes.bottom.geometry}
              position={[5.306, 51.464, 5.261]}
            >
              <primitive
                attach="material"
                object={basicMaterials.toonBlack}
              />
            </mesh>

            <mesh
              name="city-mesh"
              geometry={nodes['city-mesh'].geometry}
              scale={[250, 33.211, 250]}
            >
              <primitive
                attach="material"
                object={basicMaterials.toonBlack}
              />
            </mesh>

            <group
              name="Man"
              position={[15.443, 29.469, 15.406]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={1.407}
            >
              <mesh geometry={nodes.Man.geometry}>
                <MaterialOutline
                  color={'white'}
                  thickness={1.05}
                  depthTest={true}
                />
              </mesh>
              <mesh
                name="Man"
                geometry={nodes.Man.geometry}
              >
                <meshBasicMaterial
                  color={'black'}
                  depthTest={false}
                />
              </mesh>
            </group>
          </Select>
        </HoverControls>
      </group>
    </group>
  );
};

useGLTF.preload(url);
