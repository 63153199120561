import { useGLTF, PerspectiveCamera, useTexture } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Select } from '@react-three/postprocessing';
import { useRef, useMemo, useEffect, useContext, useState } from 'react';
import { Sky } from '@/webgl/objects/Sky';
import { TextRings } from '@/webgl/objects/TextRings';
import { MaterialOutline } from '@/webgl/materials/MaterialOutline';
import { MaterialProject } from '@/webgl/materials/MaterialProject';
import {
  MathUtils,
  BackSide,
  FrontSide,
  DoubleSide,
  NearestFilter,
} from 'three';
import { CameraContext } from '@/webgl/utils/CameraContext';
import { HoverControls } from '@/webgl/controls/HoverControls';
import { HoldZoomControls } from '@/webgl/controls/HoldZoomControls';
import { MobileDistance } from '@/webgl/utils/MobileDistance';
import { useAnimationProgress } from '@/webgl/hooks/useAnimationProgress';
import { useAnimations } from '@/webgl/hooks/useAnimations';
import { MaterialLines } from '@/webgl/materials/MaterialLines';
import { MaterialDots } from '@/webgl/materials/MaterialDots';
import Env from '@/helpers/Env';
import * as basicMaterials from '@/webgl/materials';
import { useAppStore } from '@/stores/app';
import { WatchButton } from '@/webgl/utils/WatchButton';

// const url = 'assets/models/subway/subway.glb';
const url = 'assets/models-compressed/subway/subway-o.glb';

export const Subway = ({ project, progress, enabled, ...props }) => {
  const { size } = useThree();

  const color = project.color;

  // CAMERA
  const refCamera = useContext(CameraContext);

  // GLTF
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(url);

  // ANIMATION
  useAnimationProgress(group, animations[0], progress);

  // useAnimations(group, animations[0], 2);

  // TOON MATERIAL GRADIENT
  const gradientMap = useTexture('assets/images/threeTone.jpg');
  gradientMap.minFilter = NearestFilter;
  gradientMap.magFilter = NearestFilter;

  // PATTERN
  const [pattern, setPattern] = useState('lines');
  useEffect(() => {
    setPattern(Math.random() > 0.35 ? 'lines' : 'dots');
  }, [project]);

  return (
    <group>
      <directionalLight
        intensity={(Env.mobile ? 0.8 : 1) * 0.04}
        position={[-1, 2, 3]}
      />

      <group
        ref={group}
        {...props}
        dispose={null}
      >
        <group
          name="CameraAnimation"
          position={[-3.783, 1.555, -11.578]}
          rotation={[0, -Math.PI / 2, 0]}
        >
          <HoverControls
            easeX={0.1}
            easeY={0.1}
            easeZ={0.1}
            posX={0.2}
            posY={Env.mobile ? -0.05 : -0.1}
            posZ={1}
            rotX={Env.mobile ? 0.05 : 0.1}
            rotY={0.2}
            rotZ={-0.05}
          >
            <HoldZoomControls
              from={36}
              to={36 - 6}
            >
              <MobileDistance distance={(size.height / size.width) * 2.5}>
                <PerspectiveCamera
                  name="Camera"
                  ref={refCamera}
                  makeDefault={true}
                  far={1000}
                  near={0.1}
                  fov={Env.mobile ? 41 : 36}
                  position={[1.573, -0.86, 2.631]}
                  rotation={[0.098, 0, 0]}
                />
              </MobileDistance>
            </HoldZoomControls>
          </HoverControls>
        </group>

        <group name="Scene">
          <group rotation={[MathUtils.degToRad(90), 0, 0]}>
            <Sky
              shape={'cylinder'}
              radius={20}
              length={100}
              color={color}
              speed={0.013}
              freq={74}
              rad={0.2}
              rep={200}
              sharpness={10.0}
              pattern={pattern}
            />
          </group>
          {/* <group rotation={[MathUtils.degToRad(90), 0, 0]}>
            <TextRings
              position={[0, -62, 0]}
              project={project}
              radius={20}
              length={100}
              height={0.5}
              speedMultiplier={0}
              repeat={Math.ceil(34 / (project.tags?.length || 1))}
              enabled={enabled}
            />
          </group> */}

          {/* <mesh
            name="Cylinder"
            geometry={nodes.Cylinder.geometry}
            position={[6.784, 0, 0]}
            rotation={[Math.PI / 2, 0, 0]}
          >
            <MaterialLines
              color={color}
              side={DoubleSide}
              speed={-0.01}
              freq={200}
              gap={0.5}
            />
          </mesh> */}

          <group position={[5.367, -0.19, 4.009]}>
            <mesh
              name="Train"
              geometry={nodes.Train.geometry}
            >
              <MaterialOutline
                color={'white'}
                thickness={1.05}
                side={BackSide}
                depthTest={false}
              />
            </mesh>

            <mesh
              name="Train"
              geometry={nodes.Train.geometry}
            >
              {/* <meshBasicMaterial
                color={'black'}
                depthTest={false}
              /> */}
              {/* <primitive
                attach="material"
                object={basicMaterials.toonBlack}
              /> */}
              <meshToonMaterial
                color={'#666'}
                gradientMap={basicMaterials.gradientMap}
                depthTest={true}
              />
            </mesh>
          </group>

          <Select enabled>
            <mesh
              name="Ground"
              geometry={nodes.Plane.geometry}
            >
              {/* <primitive
                attach="material"
                object={basicMaterials.toonBlack}
              /> */}
              {/* <meshBasicMaterial color={'red'} /> */}
              <meshToonMaterial
                color={'#666'}
                gradientMap={basicMaterials.gradientMap}
                depthTest={false}
              />
            </mesh>
          </Select>

          <Select enabled>
            <group
              name="Pillars"
              position={[0, 1.295, -4.676]}
            >
              <mesh geometry={nodes.Pillars.geometry}>
                {/* <primitive
                  attach="material"
                  object={basicMaterials.toonBlackNoDepth}
                /> */}
                <meshToonMaterial
                  color={'#666'}
                  gradientMap={basicMaterials.gradientMap}
                  depthTest={false}
                />
              </mesh>
            </group>

            <WatchButton
              project={project}
              enabled={enabled}
            >
              <group
                name="Screen"
                position={[0, 1.45, 6.949]}
                rotation={[0, 0, -Math.PI / 2]}
              >
                <mesh geometry={nodes.Screen.geometry}>
                  <MaterialProject
                    enabled={enabled}
                    project={project}
                  />
                </mesh>
              </group>
            </WatchButton>
          </Select>

          <Select enabled={true}>
            <group
              position={[-2.204, 0.02, 1.308]}
              rotation={[Math.PI / 2, 0, -1.983]}
            >
              <mesh
                name="Man"
                geometry={nodes.Man.geometry}
              >
                <MaterialOutline
                  color={'white'}
                  thickness={1.015}
                  side={BackSide}
                  depthTest={false}
                  transparent={true}
                />
              </mesh>
              <mesh
                name="Man"
                geometry={nodes.Man.geometry}
              >
                <meshBasicMaterial
                  color={'black'}
                  depthTest={false}
                  transparent={true}
                />
              </mesh>
            </group>
          </Select>
        </group>
      </group>
    </group>
  );
};

useGLTF.preload(url);
