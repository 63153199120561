import { useTexture } from '@react-three/drei';
import { useThree, useLoader } from '@react-three/fiber';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Texture, RepeatWrapping, TextureLoader } from 'three';

export const MaterialImage = ({ src, start, ...props }) => {
  const { gl } = useThree();

  // const texture = useTexture(src);
  // const texture = useLoader(TextureLoader, src);

  const refMaterial = useRef(null);
  const [texture, setTexture] = useState(null);

  useLayoutEffect(() => {
    // const texture = new Texture(src);

    setTexture(null);

    // refMaterial.current.color.setHex('#000000');
    const t = new TextureLoader().load(src, () => {
      t.wrapS = t.wrapT = RepeatWrapping;
      t.colorSpace = gl.outputColorSpace;
      const aspect = t.image.naturalWidth / t.image.naturalHeight;
      const repeatX = aspect < 1 ? 3 : 1;
      t.repeat.set(repeatX, 1);
      // if (refMaterial.current) refMaterial.current.map = texture;
      // refMaterial.current.map.needsUpdate = true;
      // refMaterial.current.color.set('#FFFFFF');
      setTexture(t);
    });

    // return () => {
    //   texture.dispose();
    // };
  }, [src, gl.outputColorSpace]);

  return (
    <>
      {texture && (
        <meshBasicMaterial
          // ref={refMaterial}
          map={texture}
          {...props}
        />
      )}
    </>
  );
};
