import { useRef, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';

import LogEase from '@/helpers/LogEase';

export const FollowCursor = ({ children, ease = 0.1 }) => {
  const { size } = useThree();
  const refRoot = useRef();

  const position = useRef({
    x: new LogEase({ ease }),
    y: new LogEase({ ease }),
  });

  useEffect(() => {
    position.current.x.ease = ease || 0.1;
    position.current.y.ease = ease || 0.1;
  }, [ease]);

  useFrame((state) => {
    position.current.x.update(state.pointer.x * (size.width / 2));
    position.current.y.update(state.pointer.y * (size.height / 2));

    refRoot.current.position.x = position.current.x.current;
    refRoot.current.position.y = position.current.y.current;
  });

  return <group ref={refRoot}>{children}</group>;
};
