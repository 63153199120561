import { MathUtils } from 'three';
import { useState, useEffect } from 'react';
import { Wiggle } from '@/webgl/utils/Wiggle';
import { motion } from 'framer-motion-3d';

export const Arrow = ({
  children,
  active,
  position,
  rotation,
  scale,
  shapeScale,
  materialProps,
  ...props
}) => {
  // const { y, opacity } = useSpring({
  //   y: active ? 0 : 1,
  //   opacity: active ? 1 : 0,
  //   config: {
  //     mass: 0.1,
  //     friction: 10,
  //   },
  // });
  // const y = 0;
  // const opacity = 1;

  const [color, setColor] = useState(props.color);
  useEffect(() => {
    setColor(props.color);
  }, [props.color]);

  const anim = {
    mesh: {
      initial: { y: 1 },
      animate: { y: 0, transition: { duration: 0.1 } },
      exit: { y: 1, transition: { duration: 0.1 } },
    },
    material: {
      initial: { color: '#FFFFFF' },
      animate: {
        color: color,
        transition: {
          color: {
            ease: 'easeInOut',
            duration: 0.1,
          },
        },
      },
      exit: { opacity: 0, transition: { duration: 0.1 } },
    },
  };

  return (
    <Wiggle>
      <group
        name="Arrow"
        position={position}
        scale={scale}
        rotation={rotation}
      >
        <group rotation={[0, 0, MathUtils.degToRad(-90)]}>
          <mesh
            name="Arrow-shapeScale"
            {...props}
            scale={shapeScale}
            position={[0, shapeScale[1], 0]}
          >
            <motion.mesh
              rotation={[MathUtils.degToRad(90), 0, 0]}
              variants={anim.mesh}
              initial="initial"
              animate="animate"
              exit="exit"
              name="Arrow-shape"
            >
              <coneGeometry args={[1, 0, 3]} />

              <motion.meshBasicMaterial
                transparent={true}
                variants={anim.material}
                initial="initial"
                animate="animate"
                exit="exit"
                {...materialProps}
              />
              {/* {children} */}
            </motion.mesh>
          </mesh>
        </group>
        {/* <mesh>
          <circleGeometry args={[1]} />
          <meshBasicMaterial color={'#FF0000'} />
        </mesh> */}
      </group>
    </Wiggle>
  );
};
