import { Howl } from 'howler';

export const bgm = new Howl({
  src: 'assets/sounds/bgm.mp3',
  volume: 1,
  loop: true,
});
export const menu = new Howl({ src: 'assets/sounds/00044.wav', volume: 2 });
export const menu2 = new Howl({
  src: 'assets/sounds/00302_streaming.wav',
  volume: 2,
});
export const hover = new Howl({
  src: 'assets/sounds/00356_streaming.wav',
  volume: 2,
});
export const click = new Howl({ src: 'assets/sounds/00136.wav', volume: 3 });
