import { useMemo } from 'react';
import { DoubleSide, MathUtils } from 'three';
import { Plane, Text, useMask } from '@react-three/drei';
import { randomInRange, randomIntRange } from '@/helpers/MathUtils';
import { MeshWobble2DMaterial } from '@/webgl/materials/MeshWobble2DMaterial';
import Env from '@/helpers/Env';

export const OutlineLetter = ({
  hasOutline = true,
  children,
  fontSize = 10,
  rotationAmount = 12,
  materialProps = {},
  ...props
}) => {
  const rotation = useMemo(() => {
    let amount = rotationAmount;
    return [0, 0, MathUtils.degToRad(randomInRange(-amount, amount))];
  }, [rotationAmount]);

  const hasBg = useMemo(() => {
    return Math.random() > 0.5 ? true : false;
  }, []);

  const isStroke = useMemo(() => {
    return Math.random() > 0.666 ? true : false;
  }, []);

  const bg = useMemo(() => {
    let i = randomIntRange(0, 1);
    return ['black', 'white'][i];
  }, []);

  const bgInvert = bg == 'black' ? 'white' : 'black';

  const isUpperCase = useMemo(() => {
    return props.idx == 0 || (Math.random() > 0.75 ? true : false);
  }, []);

  let letter = props.letter;
  if (!props.lockCase) {
    letter = isUpperCase
      ? props.letter.toUpperCase()
      : props.letter.toLowerCase();
  }

  const font = 'assets/fonts/Persona/Persona.otf';

  let stencil = useMask(props.maskId == undefined ? 10 : props.maskId);
  // if (!props.maskId) stencil = {};

  // const squareBg = useMemo(() => (Math.random() > 0.5 ? true : false), []);

  return (
    <>
      <group rotation={rotation}>
        {/* Space (for flex calc) */}
        {letter == ' ' && (
          <mesh visible={false}>
            <sphereGeometry args={[fontSize * 0.35]} />
          </mesh>
        )}

        {/* White border */}
        <Text
          fontSize={fontSize}
          text={letter}
          font={font}
          color={'white'}
          outlineWidth={fontSize * 0.45}
          outlineColor={'white'}
          position={[0, 0, 1]}
          visible={hasOutline}
        >
          <MeshWobble2DMaterial
            side={DoubleSide}
            speed={5}
            factor={1}
            {...materialProps}
            {...stencil}
          />
        </Text>

        {/* Black border */}
        <Text
          fontSize={fontSize}
          text={letter}
          font={font}
          color={'black'}
          outlineWidth={fontSize * 0.3}
          outlineColor={'black'}
          position={[0, 0, 2]}
        >
          <MeshWobble2DMaterial
            side={DoubleSide}
            speed={5}
            factor={1}
            {...materialProps}
            {...stencil}
          />
        </Text>

        {/* LETTER */}
        <Text
          fontSize={fontSize}
          text={letter}
          font={font}
          color={'white'}
          fillOpacity={isStroke ? 0 : 1}
          strokeWidth={isStroke ? 2 : 0}
          strokeColor={'white'}
          position={[0, 0, 3]}
        >
          <MeshWobble2DMaterial
            side={DoubleSide}
            speed={5}
            factor={1}
            {...materialProps}
            {...stencil}
          />
        </Text>
      </group>
    </>
  );
};
