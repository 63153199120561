import { useGLTF, PerspectiveCamera } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useRef, useEffect, useContext, useState } from 'react';
import { MathUtils, BackSide, FrontSide, DoubleSide } from 'three';
import { CameraContext } from '@/webgl/utils/CameraContext';
import { HoverControls } from '@/webgl/controls/HoverControls';
import { useAnimationProgress } from '@/webgl/hooks/useAnimationProgress';
import { useAnimations } from '@/webgl/hooks/useAnimations';
import { Ground } from '@/webgl/objects/Ground';
import { Sky } from '@/webgl/objects/Sky';
import { TextRings } from '@/webgl/objects/TextRings';
import { Select } from '@react-three/postprocessing';
import { MaterialProject } from '@/webgl/materials/MaterialProject';
import { MaterialText } from '@/webgl/materials/MaterialText';
import { HoldZoomControls } from '@/webgl/controls/HoldZoomControls';
import { MobileDistance } from '@/webgl/utils/MobileDistance';
import Env from '@/helpers/Env';
import * as basicMaterials from '@/webgl/materials';
import { useAppStore } from '@/stores/app';
import { WatchButton } from '@/webgl/utils/WatchButton';

// const url = 'assets/models/intro/intro.glb';
const url = 'assets/models-compressed/intro/intro-o.glb';

export const Intro = ({ project, progress, enabled, ...props }) => {
  const { size } = useThree();

  const color = project.color;

  // CAMERA
  const refCamera = useContext(CameraContext);

  // GLTF
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(url);

  // ANIMATION
  useAnimationProgress(group, animations[0], progress);

  // useAnimations(group, animations[0]);

  const [pattern, setPattern] = useState('lines');
  useEffect(() => {
    setPattern(Math.random() > 0.35 ? 'lines' : 'dots');
  }, [project]);

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
    >
      <Ground
        size={300}
        color={color}
        showSky={false}
      />

      <group rotation={[MathUtils.degToRad(-90), 0, 0]}>
        <Sky
          size={1000}
          radius={1000}
          length={3000}
          color={color}
          speed={0.01}
          freq={Env.mobile ? 50 : 100}
          shape={'sphere'}
          pattern={'text'}
        />
      </group>

      <group name="Scene">
        {/* <HoverControls
          easeX={0.1}
          easeY={0.1}
          easeZ={0.15}
          posX={0}
          posY={0}
          posZ={0}
          rotX={-0}
          rotY={-0.2}
          rotZ={-0}
        > */}
        <Select enabled>
          <mesh
            name="Man"
            geometry={nodes.Man.geometry}
            position={[2.806, 0.043, -6.651]}
            rotation={[Math.PI / 2, 0, 2.939]}
          >
            <primitive
              attach="material"
              object={basicMaterials.black}
            />
          </mesh>
        </Select>

        <WatchButton
          project={project}
          enabled={false}
        >
          <group
            position={[0, 2.97, -11.244]}
            scale={1.816}
          >
            <mesh
              name="Plane"
              geometry={nodes.Plane.geometry}
              scale={[1.014, 1 + 0.014 * (1920 / 1080), 1]}
            >
              <primitive
                attach="material"
                object={basicMaterials.white}
              />
            </mesh>
            <mesh
              name="Plane"
              geometry={nodes.Plane.geometry}
              position={[0, 0, 0.01]}
            >
              <MaterialProject
                enabled={enabled}
                project={project}
              />
            </mesh>
          </group>
        </WatchButton>

        <group
          name="CameraAnimation"
          position={[0, 1.081, 10.488]}
          rotation={[0.105, -0.002, -0.017]}
        >
          <HoverControls
            easeX={0.1}
            easeY={0.1}
            easeZ={0.1}
            posX={2}
            posY={-0.5}
            posZ={0}
            rotX={Env.mobile ? 0 : 0.05}
            rotY={0.2}
            rotZ={-0.1}
          >
            <HoldZoomControls
              from={26}
              to={26 - 6}
            >
              <MobileDistance distance={(size.height / size.width) * 0}>
                <PerspectiveCamera
                  name="Camera"
                  ref={refCamera}
                  makeDefault={true}
                  far={2000}
                  near={0.1}
                  fov={Env.mobile ? 58 : 26}
                  rotation={[MathUtils.degToRad(2), 0, 0]}
                />
              </MobileDistance>
            </HoldZoomControls>
          </HoverControls>
        </group>
      </group>
    </group>
  );
};

useGLTF.preload(url);
