import { useMemo, useEffect } from 'react';
import { Color, Vector2 } from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import vertexShader from '@/webgl/glsl/base.vert';
import fragmentShader from '@/webgl/glsl/dots.frag';

const defaults = {
  speedX: 0.005,
  speedY: -0.005,
  rad: 0.2,
  rep: 300,
  sharpness: 50.0,
};

export const MaterialDots = ({ ...props }) => {
  const { size } = useThree();
  const uniforms = useMemo(() => {
    return {
      uColor: {
        value: new Color('#FFFFFF'),
      },
      uTime: {
        value: 0.0,
      },
      iResolution: {
        value: new Vector2(size.width, size.height),
      },

      uSpeedX: {
        value: defaults.speedX,
      },
      uSpeedY: {
        value: defaults.speedY,
      },
      uRad: {
        value: defaults.rad,
      },
      uRep: {
        value: defaults.rep,
      },
      uSharpness: {
        value: defaults.sharpness,
      },
    };
  }, []);

  useEffect(() => {
    let c = new Color(props.color);
    uniforms.uColor.value.r = c.r;
    uniforms.uColor.value.g = c.g;
    uniforms.uColor.value.b = c.b;

    uniforms.iResolution.value.x = size.width;
    uniforms.iResolution.value.y = size.height;

    uniforms.uSpeedX.value = props.speedX || defaults.speedX;
    uniforms.uSpeedY.value = props.speedY || defaults.speedY;
    uniforms.uRad.value = props.rad || defaults.rad;
    uniforms.uRep.value = props.rep || defaults.rep;
    uniforms.uSharpness.value = props.sharpness || defaults.sharpness;
  }, [props, uniforms, size]);

  useFrame((state, delta) => {
    uniforms.uTime.value += delta;
  });

  return (
    <shaderMaterial
      fragmentShader={fragmentShader}
      vertexShader={vertexShader}
      uniforms={uniforms}
      {...props}
    />
  );
};
