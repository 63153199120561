import { DoubleSide, MathUtils } from 'three';
import { MaterialLines } from '@/webgl/materials/MaterialLines';
import { MaterialDots } from '@/webgl/materials/MaterialDots';
import { MaterialText } from '@/webgl/materials/MaterialText';
import { useControls } from 'leva';

export const Sky = ({
  size = 100,
  shape = 'sphere',
  pattern = 'lines',
  ...props
}) => {
  return (
    <mesh
      name="sky"
      position={[0, 0, 0]}
      rotation={[MathUtils.degToRad(180), 0, 0]}
    >
      <>
        {
          {
            box: <boxGeometry args={[size, size, size]} />,
            sphere: <sphereGeometry args={[size, 128, 16]} />,
            cylinder: (
              <cylinderGeometry
                args={[props.radius, props.radius, props.length, 128, 1, true]}
              />
            ),
          }[shape]
        }
      </>

      <>
        {
          {
            lines: (
              <MaterialLines
                color={props.color}
                side={DoubleSide}
                {...props}
              />
            ),
            dots: (
              <MaterialDots
                color={props.color}
                side={DoubleSide}
                {...props}
              />
            ),
            // text: (
            //   <MaterialText
            //     color={props.color}
            //     side={DoubleSide}
            //     {...props}
            //   />
            // ),
          }['lines']
        }
      </>

      {/* <meshBasicMaterial
        color={'red'}
        side={DoubleSide}
        {...props}
      /> */}
    </mesh>
  );
};
