import { useState, useEffect, Suspense } from 'react';
import { Scenes } from '@/webgl/scenes';
import { Canvas } from '@react-three/fiber';
import { PerformanceMonitor } from '@react-three/drei';
import Env from '@/helpers/Env';
import { Loading } from '@/components/Loading';
import { AnimatePresence } from 'framer-motion';
import { ui } from '@/tunnels';
import { A11yAnnouncer } from '@react-three/a11y';
import round from 'lodash/round';
import { Stats } from '@react-three/drei';
import { Perf } from 'r3f-perf';
import { getUrlBoolean } from '@/helpers/UrlParam';
import { ColorManagement } from 'three';
ColorManagement.enabled = true;

const debug = getUrlBoolean('debug', false);

export function Webgl(props) {
  const [dpr, setDpr] = useState(Env.mobile ? 2 : 1.5);

  return (
    <>
      <Canvas
        dpr={dpr}
        gl={{ powerPreference: 'high-performance', antialias: false }}
        flat
      >
        <PerformanceMonitor
          // bounds={(refreshrate) => (refreshrate > 90 ? [30, 90] : [30, 60])}
          onChange={({ fps, factor, refreshrate, frames, averages }) => {
            let d = round(0.5 + 1.0 * factor, 1);
            d = Math.min(d, 2);
            d = Math.max(d, Env.mobile ? 1.5 : 1);
            setDpr(d);
          }}
          // flipflops={3}
          // onFallback={() => setDpr(0.5)}
        />

        {debug && (
          <>
            <axesHelper />
            <Stats />
            {/* <Perf /> */}
          </>
        )}

        <Scenes />
      </Canvas>
      <A11yAnnouncer />
    </>
  );
}
