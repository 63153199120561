import { useRef, useEffect } from 'react';
import { useFrame, useThree } from '@react-three/fiber';

import LogEase from '@/helpers/LogEase';
import Env from '@/helpers/Env';

export const HoverControls = ({
  children,
  easeX = 0.1,
  easeY = 0.1,
  easeZ = 0.1,
  posX = 0.1,
  posY = 0.1,
  posZ = 0.1,
  rotX = -0.1,
  rotY = -0.1,
  rotZ = 0.1,
  ...props
}) => {
  const refRoot = useRef();

  const { gl, size } = useThree();

  const mouse = useRef({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const onTouchMove = (evt) => {
      mouse.current.x = (evt.x / size.width) * 2 - 1;
      mouse.current.y = (evt.y / size.height) * 2 - 1;
    };

    window.addEventListener('mousemove', onTouchMove);

    return () => {
      window.removeEventListener('mousemove', onTouchMove);
    };
  }, [size]);

  const position = useRef({
    x: new LogEase({ ease: easeX }),
    y: new LogEase({ ease: easeY }),
    z: new LogEase({ ease: easeZ }),
  });

  const rotation = useRef({
    x: new LogEase({ ease: easeX }),
    y: new LogEase({ ease: easeY }),
    z: new LogEase({ ease: easeZ }),
  });

  useEffect(() => {
    position.current.x.ease = easeX;
    position.current.y.ease = easeY;
    position.current.z.ease = easeZ;

    rotation.current.x.ease = easeX;
    rotation.current.y.ease = easeY;
    rotation.current.z.ease = easeZ;
  }, [easeX, easeY, easeZ]);

  useFrame((state) => {
    if (Env.mobile) return;
    let x = mouse.current.x;
    let y = mouse.current.y;

    position.current.x.update(x * posX);
    position.current.y.update(y * posY);
    position.current.z.update(y * posZ);

    rotation.current.x.update(y * rotX);
    rotation.current.y.update(x * rotY);
    rotation.current.z.update(x * rotZ);

    refRoot.current.position.x = position.current.x.current;
    refRoot.current.position.y = position.current.y.current;
    refRoot.current.position.z = position.current.z.current;

    refRoot.current.rotation.x = rotation.current.x.current;
    refRoot.current.rotation.y = rotation.current.y.current;
    refRoot.current.rotation.z = rotation.current.z.current;
  });

  return <group ref={refRoot}>{children}</group>;
};
