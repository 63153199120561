import { useGLTF, PerspectiveCamera } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Select } from '@react-three/postprocessing';
import { useRef, useEffect, useContext, useState } from 'react';

import { Ground } from '@/webgl/objects/Ground';
import { Sky } from '@/webgl/objects/Sky';
import { TextRings } from '@/webgl/objects/TextRings';
import { MaterialOutline } from '@/webgl/materials/MaterialOutline';
import { MaterialProject } from '@/webgl/materials/MaterialProject';
import {
  MathUtils,
  BackSide,
  FrontSide,
  DoubleSide,
  NearestFilter,
} from 'three';
import { CameraContext } from '@/webgl/utils/CameraContext';
import { HoverControls } from '@/webgl/controls/HoverControls';
import { HoldZoomControls } from '@/webgl/controls/HoldZoomControls';
import { MobileDistance } from '@/webgl/utils/MobileDistance';
import { useAnimationProgress } from '@/webgl/hooks/useAnimationProgress';
import { useAnimations } from '@/webgl/hooks/useAnimations';
import { MaterialLines } from '@/webgl/materials/MaterialLines';
import { LayerWithEffects } from '@/webgl/utils/LayerWithEffects';
import * as basicMaterials from '@/webgl/materials';
import { useAppStore } from '@/stores/app';
import { WatchButton } from '@/webgl/utils/WatchButton';
import Env from '@/helpers/Env';

// const url = 'assets/models/picadilly/picadilly.glb';
const url = 'assets/models-compressed/picadilly/picadilly-o.glb';

export const Picadilly = ({ project, progress, enabled, ...props }) => {
  const { size } = useThree();

  const color = project.color;

  // CAMERA
  const refCamera = useContext(CameraContext);

  // GLTF
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(url);

  // ANIMATION
  useAnimationProgress(group, animations[0], progress);

  // useAnimations(group, animations[0], 2);

  const [pattern, setPattern] = useState('lines');
  useEffect(() => {
    setPattern(Math.random() > 0.35 ? 'lines' : 'dots');
  }, [project]);

  return (
    <group
      ref={group}
      {...props}
      dispose={null}
    >
      <directionalLight
        intensity={(Env.mobile ? 0.8 : 1) * 0.03}
        position={[-1, 1, 3]}
      />
      <group rotation={[MathUtils.degToRad(1), 0, 0]}>
        <Ground
          size={200}
          speed={-0.005}
          freq={120}
          gap={0.5}
          color={color}
          sharpness={10}
          pattern={pattern}
          showSky={true}
        />
      </group>
      {/* <TextRings
        position={[0, 2, 0]}
        project={project}
        radius={200}
        height={2.5}
        repeat={Math.ceil(12 / (project.tags?.length || 1))}
        speed={0.00015}
        speedMultiplier={0.4}
        enabled={enabled}
      /> */}

      <group name="Scene">
        <group
          name="CameraAnimator"
          position={[1.587, 0, 9.118]}
          rotation={[0.339, 0.499, -0.167]}
        >
          <HoverControls
            easeX={0.1}
            easeY={0.1}
            easeZ={0.1}
            posX={Env.mobile ? 1 : 1}
            posY={Env.mobile ? -0.1 : -0.5}
            posZ={2}
            rotX={Env.mobile ? 0.005 : 0.025}
            rotY={Env.mobile ? 0.05 : 0.05}
            rotZ={-0.01}
          >
            <HoldZoomControls
              from={23}
              to={23 - 6}
            >
              <MobileDistance distance={(size.height / size.width) * 0}>
                <PerspectiveCamera
                  name="Camera"
                  ref={refCamera}
                  makeDefault={true}
                  far={1000}
                  near={0.1}
                  fov={Env.mobile ? 56 : 23}
                />
              </MobileDistance>
            </HoldZoomControls>
          </HoverControls>
        </group>
        <Select enabled>
          <group position={[-10.548, 4.014, -10.105]}>
            {/* <mesh
              geometry={nodes.Screen.geometry}
              position={[0, 0, 0]}
            >
              <MaterialOutline
                color={'white'}
                thickness={1.08}
                transparent={true}
                depthTest={false}
              />
            </mesh> */}
            <WatchButton
              project={project}
              enabled={enabled}
            >
              <mesh
                name="Screen"
                geometry={nodes.Screen.geometry}
              >
                <MaterialProject
                  enabled={enabled}
                  project={project}
                  transparent={true}
                />
              </mesh>
            </WatchButton>
          </group>
          <mesh
            name="Building"
            castShadow
            receiveShadow
            geometry={nodes.Building.geometry}
            position={[-27.434, 3.567, -13.148]}
            scale={[1, 1.599, 1]}
          >
            <primitive
              attach="material"
              object={basicMaterials.toonBlack}
            />
          </mesh>
        </Select>

        <group
          name="Man"
          position={[-0.293, 0, 1.463]}
          rotation={[Math.PI / 2, 0, 2.323]}
        >
          <mesh geometry={nodes.Man.geometry}>
            <MaterialOutline
              color={'white'}
              thickness={1.015}
              transparent={true}
              depthTest={false}
            />
          </mesh>
          <mesh
            name="Man"
            geometry={nodes.Man.geometry}
          >
            {/* <meshBasicMaterial
              color={'black'}
              transparent={true}
              depthTest={false}
            /> */}
            <primitive
              attach="material"
              object={basicMaterials.blackTransparentNoDepth}
            />
          </mesh>
        </group>
      </group>
    </group>
  );
};

useGLTF.preload(url);
