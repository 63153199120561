import { useAppStore } from '@/stores/app';

export const WatchButton = function ({ children, project, enabled }) {
  const audioUnlocked = useAppStore((state) => state.audioUnlocked);
  const setWatch = useAppStore((state) => state.setWatch);
  const setWatchHover = useAppStore((state) => state.setWatchHover);

  const onHover = function (evt) {
    if (!audioUnlocked) return;
    if (!enabled) return;
    evt.stopPropagation();
    setWatchHover(project);
    document.body.classList.add('hand');
  };

  return (
    <group
      onClick={(evt) => {
        if (!audioUnlocked) return;
        if (!enabled) return;
        evt.stopPropagation();
        setWatch(project);
      }}
      onPointerOver={onHover}
      onPointerMove={onHover}
      onPointerOut={(evt) => {
        if (!audioUnlocked) return;
        if (!enabled) return;
        evt.stopPropagation();
        setWatchHover(null);
        document.body.classList.remove('hand');
      }}
    >
      {children}
    </group>
    // <>{children}</>
  );
};
