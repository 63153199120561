import story from '@/data/story';
import data from './data';
import { randomInRange } from '@/helpers/MathUtils';
import Env from '@/helpers/Env';

const getFilename = function (path) {
  return path.replace(/^.*[\\\/]/, '');
};

const getFilenameWoExt = function (path) {
  return path.replace(/^.*[\\\/]/, '').replace(/\.[^/.]+$/, '');
};

function getContrastYIQ(hexcolor) {
  var r = parseInt(hexcolor.substring(1, 3), 16);
  var g = parseInt(hexcolor.substring(3, 5), 16);
  var b = parseInt(hexcolor.substring(5, 7), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

// export const projects = story.story.content.body[0].projects.filter(
//   (p) => !p.hidden
// );
export const projects = data.filter((p) => !p.hidden);

let folder = 'hq';
if (Env.chrome) folder = 'hq-hevc';
if (Env.safari) folder = 'hq-hevc';
if (Env.edge) folder = 'hq-hevc';
if (Env.firefox) folder = 'lq';

if (Env.mobile) folder = 'lq';
if (Env.ios_safari) folder = 'lq-hevc';
if (Env.ios_chrome) folder = 'lq-hevc';
if (Env.android_chrome) folder = 'lq-hevc';

console.log('Video', folder);

projects.forEach((project, idx) => {
  // MEDIA
  if (project.id) {
    project.video = project.video || {};
    project.poster = project.poster || {};
    project.video.localPath = `/assets/videos/${folder}/${project.id}.mp4`;
    project.poster.localPath = `/assets/videos/posters/${project.id}.webp`;
  }

  // THEME
  project.theme = getContrastYIQ(project.color);

  // MENU BG OFFSET
  project.bg = {
    offset: idx * 0,
    skew: randomInRange(-20, 20),
    scale: {
      x: randomInRange(1, 1.2),
      y: randomInRange(1.1, 1.6),
    },
  };

  project.bg.transform = `skewX(${project.bg.skew}deg) scale(${project.bg.scale.x}, ${project.bg.scale.y})`;

  project.tags = project.tags || [];
  project.meta = [project.client, ...project.tags];
});

export default projects;
