import { useMemo, useEffect } from 'react';
import { Color } from 'three';
import { useFrame } from '@react-three/fiber';
import vertexShader from '@/webgl/glsl/base.vert';
import fragmentShader from '@/webgl/glsl/lines.frag';

const defaults = {
  speed: 60.0,
  freq: 60.0,
  gap: 0.5,
};

export const MaterialLines = ({ ...props }) => {
  const uniforms = useMemo(() => {
    return {
      uColor: {
        value: new Color(props.color || '#FFFFFF'),
      },
      uTime: {
        value: 0.0,
      },
      uSpeed: {
        value: props.speed !== undefined ? props.speed : defaults.speed,
      },
      uFreq: {
        value: props.freq !== undefined ? props.freq : defaults.freq,
      },
      uGap: {
        value: props.gap !== undefined ? props.gap : defaults.gap,
      },
    };
  }, []);

  useEffect(() => {
    let c = new Color(props.color);
    uniforms.uColor.value.r = c.r;
    uniforms.uColor.value.g = c.g;
    uniforms.uColor.value.b = c.b;
    uniforms.uSpeed.value =
      props.speed !== undefined ? props.speed : defaults.speed;
    uniforms.uFreq.value =
      props.freq !== undefined ? props.freq : defaults.freq;
    uniforms.uGap.value = props.gap !== undefined ? props.gap : defaults.gap;
  }, [props]);

  useFrame((state, delta) => {
    uniforms.uTime.value += delta;
  });

  return (
    <shaderMaterial
      fragmentShader={fragmentShader}
      vertexShader={vertexShader}
      uniforms={uniforms}
      {...props}
    />
  );
};
