import { Sphere, Plane } from '@react-three/drei';
import { MeshWobble2DMaterial } from '@/webgl/materials/MeshWobble2DMaterial';
import { DoubleSide } from 'three';
import { A11y } from '@react-three/a11y';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion-3d';
import { useAppStore } from '@/stores/app';
import { hover, click } from '@/sounds';
import * as basicMaterials from '@/webgl/materials';
const width = 35;
const height = 8;

export const MenuButton = ({ onClick, ...props }) => {
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    if (hovering) {
      document.body.classList.add('hand');
    } else {
      document.body.classList.remove('hand');
    }
    if (hovering) hover.play();
  }, [hovering]);

  const showMenu = useAppStore((state) => state.showMenu);
  const setShowMenu = useAppStore((state) => state.setShowMenu);

  return (
    <motion.group
      onClick={(evt) => {
        evt.stopPropagation();
        // onClick?.();
        click.play();
        setShowMenu(true);
      }}
      position={[width * -0.5, height * 3 * -0.5, 0]}
      onPointerOver={(evt) => setHovering(true)}
      onPointerOut={(evt) => setHovering(false)}
      animate={{ scale: hovering ? 1.4 : 1 }}
    >
      {/* <group rotation={[0, 0, MathUtils.degToRad(220)]}>
        <AnimatePresence>
          {hovering && <HoverArrows color={'red'} />}
        </AnimatePresence>
      </group> */}
      <Plane
        args={[width, height * 1.2 * 3]}
        // position={[width * -0.5, -height * 1.2, 0]}
        visible={false}
      >
        <primitive
          attach="material"
          object={basicMaterials.black}
        />
      </Plane>
      {/* <Sphere args={[4]}>
        <primitive attach="material" object={basicMaterials.black} />
      </Sphere> */}
      {[...Array(3)].map((_, i) => (
        <group
          position={[0, (i - 1) * -height * 1.2, 0]}
          key={i}
        >
          <mesh>
            <planeGeometry args={[width, height]} />
            <primitive
              attach="material"
              object={basicMaterials.white}
            />
            <MeshWobble2DMaterial
              side={DoubleSide}
              speed={5}
              factor={1 + i * 0.1}
              color={'white'}
            />
          </mesh>

          <mesh position={[0, 0, 1]}>
            <planeGeometry args={[width * 0.8, height * 0.333]} />
            <primitive
              attach="material"
              object={basicMaterials.black}
            />
            <MeshWobble2DMaterial
              side={DoubleSide}
              speed={5 + 0.3}
              factor={1 + i * 0.1}
              color={'black'}
            />
          </mesh>
        </group>
      ))}
    </motion.group>
  );
};
