import { Sphere, Plane, useTexture } from '@react-three/drei';
import { MeshWobble2DMaterial } from '@/webgl/materials/MeshWobble2DMaterial';
import { MathUtils, DoubleSide } from 'three';
import { A11y } from '@react-three/a11y';
import { useAppStore } from '@/stores/app';
import { useState, useEffect } from 'react';
import { motion } from 'framer-motion-3d';
import { hover, click } from '@/sounds';
import * as basicMaterials from '@/webgl/materials';
const size = 38;

export const MuteButton = ({ ...props }) => {
  const iconMusicOutline = useTexture('assets/images/music_outline.png');
  const iconMusicInside = useTexture('assets/images/music_inside.png');

  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    if (hovering) {
      document.body.classList.add('hand');
    } else {
      document.body.classList.remove('hand');
    }
    if (hovering) hover.play();
  }, [hovering]);

  const muted = useAppStore((state) => state.muted);
  const toggleMuted = useAppStore((state) => state.toggleMuted);
  const onClick = function (evt) {
    evt.stopPropagation();
    click.play();
    toggleMuted();
  };

  // KEYBOARD SHORTCUT
  useEffect(() => {
    const onKeyDown = function (evt) {
      switch (evt.key) {
        case 'm': {
          toggleMuted();
          break;
        }
        default: {
          break;
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <motion.group
      position={[size * -0.5, size * 0.5, 0]}
      onClick={onClick}
      onPointerOver={(evt) => setHovering(true)}
      onPointerOut={(evt) => setHovering(false)}
      animate={{ scale: hovering ? 1.4 : 1 }}
    >
      {/* <Sphere
        args={[5]}
        position={[0, 0, 0]}
      >
        <primitive attach="material" object={basicMaterials.black} />
      </Sphere> */}

      <Plane
        args={[size, size]}
        position={[0, 0, 0]}
        visible={false}
      >
        <primitive
          attach="material"
          object={basicMaterials.black}
        />
      </Plane>
      <mesh>
        <planeGeometry args={[size, size]} />
        <primitive
          attach="material"
          object={basicMaterials.white}
        />
        <MeshWobble2DMaterial
          side={DoubleSide}
          speed={5}
          factor={1}
          map={iconMusicOutline}
          transparent={true}
        />
      </mesh>

      <mesh position={[0, 0, 1]}>
        <planeGeometry args={[size, size]} />
        <primitive
          attach="material"
          object={basicMaterials.white}
        />
        <MeshWobble2DMaterial
          side={DoubleSide}
          speed={5.1}
          factor={1}
          map={iconMusicInside}
          transparent={true}
        />
      </mesh>

      <group
        position={[0, 0, 1]}
        rotation={[0, 0, MathUtils.degToRad(25)]}
        visible={muted}
      >
        <mesh>
          <planeGeometry args={[size * 1.2, size * 0.2]} />
          <primitive
            attach="material"
            object={basicMaterials.white}
          />
          <MeshWobble2DMaterial
            side={DoubleSide}
            speed={5}
            factor={1}
            color={'white'}
            transparent={true}
            depthTest={false}
          />
        </mesh>

        <mesh position={[0, 0, 1]}>
          <planeGeometry args={[size * 1.2 * 0.8, size * 0.2 * 0.333]} />
          <primitive
            attach="material"
            object={basicMaterials.black}
          />
          <MeshWobble2DMaterial
            side={DoubleSide}
            speed={5 + 0.3}
            factor={1}
            color={'black'}
            transparent={true}
            depthTest={false}
          />
        </mesh>
      </group>
    </motion.group>
  );
};
